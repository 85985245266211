import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { successNotification, errorNotification } from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import { CONTACT_FORM_LIST_API, GET_REVIEWS_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";


export const getReviewListRequest = createAsyncThunk(
    "reviewListRequest/getReviewListRequest",
    async ({ generalSearch, page }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_REVIEWS_API,
                REQUEST_METHOD.POST,
                {
                    "pagination":{"page":1,"per_page":"20"},
                    "query":{"status":"","generalSearch":""}
                },
                true,
            );
            console.log('responseresponse', response);
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getContactFormDetailsByIdRequest = createAsyncThunk(
    "autoCompleteApprovalRequest/getAutoCompleteDetailsByIdRequest",
    async (contactFormID, { rejectWithValue }) => {
        try {
            const response = await apiService(
                CONTACT_FORM_LIST_API + contactFormID,
                REQUEST_METHOD.GET,
                {},
                true,
            );
            
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);


const contactFormListSlice = createSlice({
    name: "autoCompleteApprovalRequest",
    initialState: {
        reviewListData: null,
        status: STATUSES.IDLE,
        error: null,
        contactFormDetails: null,
        contactFormDetailsStatus: STATUSES.IDLE,
    },
    extraReducers: {
        [getReviewListRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;
        },
        [getReviewListRequest.fulfilled]: (state, action) => {

            state.status = STATUSES.IDLE;
            state.reviewListData = action.payload;
        },
        [getReviewListRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },
        [getContactFormDetailsByIdRequest.pending]: (state, action) => {
            state.contactFormDetailsStatus = STATUSES.LOADING;
        },
        [getContactFormDetailsByIdRequest.fulfilled]: (state, action) => {

            state.status = STATUSES.IDLE;
            state.contactFormDetails = action.payload;
        },
        [getContactFormDetailsByIdRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },
       
    },
});
export default contactFormListSlice.reducer;

