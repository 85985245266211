import { Form, Modal } from "antd";
import React, { useEffect, useState } from "react";
import TextEditor from "../../../components/TextEditor/TextEditor";
import SaveButton from "../../../components/button/saveButton";
import BaseInput from "../../../components/form/BaseInput";
import { editConfigurationalDisciplineRequest, getConfigurationalDisciplineListRequest } from "../../../redux/systemConfigurationSlice";
import { STATUSES } from "../../../config/constants/defaultValues";
import { useDispatch } from "react-redux";

const DisciplineEditModal = ({
    isModalOpen,
    setIsModalOpen,
    modalData,
}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [isEditorEmpty, setIsEditorEmpty] = useState(false);

    const [requirementEditorData, setRequirementEditorData] = useState();
    const [requirementEditorEdit, setRequirementEditorEdit] = useState();

    const [benefitsEditorData, setBenefitsEditorData] = useState();
    const [benefitsEditorEdit, setBenefitsEditorEdit] = useState();

    const [descriptionEditorData, setDescriptionEditorData] = useState();
    const [descriptionEditorEdit, setDescriptionEditorEdit] = useState();
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        if (modalData) {
            form.setFieldsValue({
                name: modalData?.name
            });
            setRequirementEditorEdit(modalData?.requirements ?? "")
            setBenefitsEditorEdit(modalData?.benefits ?? "")
            setDescriptionEditorEdit(modalData?.description ?? "")
        }

    }, [modalData])

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    //Save Button
    const onSave = async (formData) => {
        var values = {
            ...formData,
            id: modalData?.id,
            discipline_id: modalData?.id,
            requirements: requirementEditorData,
            description: descriptionEditorData,
            benefits: benefitsEditorData,
            status: "1",
            _method: 'PUT'
        }
        setIsLoading(true);
        await dispatch(editConfigurationalDisciplineRequest({
            values
        })).then((data) => {
            if (data.payload.status === 200) {
                dispatch(getConfigurationalDisciplineListRequest({}));
                setIsModalOpen(false);
            }
            setIsLoading(false);

        })
    }
    return (
        <>
            <Modal
                className="modal-styles"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                centered={true}
                title={false}
            >
                <div className="row">
                    <div className="col-md-12">
                        <h5 className="table-heading mb-0 ">Edit Discipline</h5>
                    </div>
                </div>
                <div className="modal-content">
                    <Form layout="vertical" className="pt-3" onFinish={onSave} form={form}>
                        <div className="row">
                            <div className="col-sm-12 d-flex justify-content-between align-items-start">
                                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <BaseInput
                                        inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                        labelText="Discipline"
                                        name="name"
                                        placeText="Add Discipline"
                                    />
                                </div>
                                <SaveButton loading={isLoading ? STATUSES.LOADING : false} title="Save" />
                            </div>
                        </div>

                        <div class="row">
                            <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                                <TextEditor
                                    labelText="Description"
                                    setEditorData={setDescriptionEditorData}
                                    editorEditData={descriptionEditorEdit}
                                    setIsEditorEmpty={setIsEditorEmpty}
                                />
                            </div>
                            <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                                <TextEditor
                                    labelText="Requirements"
                                    setEditorData={setRequirementEditorData}
                                    editorEditData={requirementEditorEdit}
                                    setIsEditorEmpty={setIsEditorEmpty}
                                />
                            </div>
                            <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12">
                                <TextEditor
                                    labelText="Benefits"
                                    setEditorData={setBenefitsEditorData}
                                    editorEditData={benefitsEditorEdit}
                                    setIsEditorEmpty={setIsEditorEmpty}
                                />
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    );
};
export default DisciplineEditModal;
