
import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";

function EChart({ seriesData, xCategories }) {

  const eChartConfig = {
    series: [
      {
        name: "Applies",
        data: seriesData,
        color: "#fff",
      },
    ],

    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "auto",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
      },
      grid: {
        show: true,
        borderColor: "#ccc",
        strokeDashArray: 2,
      },
      xaxis: {
        categories: xCategories,
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: Array(xCategories.length).fill("#fff"),
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: Array(xCategories.length).fill("#fff"),
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val;
          },
        },
      },
    }
  };
  return (
    <>
      <div id="chart">
        <ReactApexChart
          className="bar-chart"
          options={eChartConfig.options}
          series={eChartConfig.series}
          type="bar"
          height={340}
        />
      </div>

    </>
  );
}

export default EChart;
