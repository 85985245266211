import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { USER_TYPE } from "../constants/defaultValues";

export const ProtectedRoute = ({ children }) => {
  const { userData, userType } = useSelector((state) => state.auth);

  if (userData) {
    if (userType === USER_TYPE.SUPER_ADMIN)
      return children;
    else
      return children;

  }
  else
    return <Navigate to="/login" />;

};
