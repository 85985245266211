import {
    MailOutlined, SearchOutlined
} from "@ant-design/icons";
import { Button, Col, Input, Pagination, Row, Space, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import { ReactComponent as DownloadImage } from "../../assets/images/download.svg";
import RangePickers from "../../components/DatePicker/RangePickers";
import { errorNotification } from "../../components/notifications/alertNotification";
import { STATUSES } from "../../config/constants/defaultValues";
import { getEmailContactListRequest, getEmailFormExportListRequest } from "../../redux/contactFormListSlice";
import { JobRequestStyle } from "./../style";
import { SystemConfigurationWrapped } from "./../systemConfiguration/style";


const EmailContactFormList = () => {
    const dispatch = useDispatch();
    const { emailStatus, emailContactListData } = useSelector(
        (state) => state.contactFormList
    );
    const [search, setSearch] = useState();
    const [exportLoading, setExportLoading] = useState(false);
    // const [form] = Form.useForm();
    const [dateRange, setDateRange] = useState("");



    useEffect(() => {
        dispatch(getEmailContactListRequest({}));
    }, [dispatch]);

    const Mailto = ({ email, subject = '', body = '', children }) => {
        let params = subject || body ? '?' : '';
        if (subject) params += `subject=${encodeURIComponent(subject)}`;
        if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

        return <a href={`mailto:${email}${params}`}>{children}</a>;
    };

    const paginationOnChange = (data, pageSize) => {
        dispatch(
            getEmailContactListRequest({ generalSearch: search, page: data, pageSize: pageSize, dateRange })
        );
    };

    const onDateChange = ({ dateRange }) => {
        setDateRange(dateRange);
        dispatch(getEmailContactListRequest({
            generalSearch: search,
            dateRange: dateRange,
        }));
    };

    const onSearch = (generalSearch) => {
        console.log('onSearchonSearchonSearch', generalSearch)
        setSearch(generalSearch);
        dispatch(getEmailContactListRequest({
            generalSearch, dateRange: dateRange,
        }));
    };

    const exportToExcel = async () => {
        setExportLoading(true);
        try {
            const data = await dispatch(getEmailFormExportListRequest({
                generalSearch: search,
                dateRange: dateRange,
            }));

            let filtered = [...data.payload];
            console.log('filtered', filtered);
            const worksheet = XLSX.utils.json_to_sheet(
                filtered.map((record) => ({
                    email: record.email,
                    "Contact Date": record.updated_at,
                }))
            );

            const currentDateTimeString = moment(new Date()).format('MM-DD-YYYY-hh:mm-A');
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, `imperial-Contact-DataSheet-${currentDateTimeString}.xlsx`);
        } catch (error) {
            errorNotification('Error while fetching or processing data:', error)
        } finally {
            setExportLoading(false);
        }
    };

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            width: 50,
            key: "id",
        },
        {
            title: "Email",
            dataIndex: "email",
            ellipsis: true,
            width: 190,
        },
        {
            title: "Contact Data",
            dataIndex: "updated_at",
            ellipsis: true,
            width: 120,
            // render: (text) => moment(text).format('MM-DD-YYYY'),
            sorter: (a, b) => {
                // Convert the date strings to Date objects for comparison
                const dateA = new Date(a.updated_at);
                const dateB = new Date(b.updated_at);

                return dateA - dateB; // To sort in ascending order
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            width: 100,
            render: (text, record) => (
                <Mailto email={record.email} subject="" body="">
                    <Space>
                        <MailOutlined />
                        Replay
                    </Space>
                </Mailto>
            ),
        },

    ];

    return (
        <JobRequestStyle>
            <div className="layout-content">
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                        <div className="row  pb-3 pb-sm-5">
                            <div className="col-sm-9">
                                <div className="heading">Email Contact List</div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-7 col-md-5 col-sm-12">
                            <RangePickers
                                name="Date_Posted"
                                req={false}
                                labelText="Date Range"
                                onChange={(_, dateStrings) => {
                                    if (dateStrings[0] == "") {
                                        onDateChange({ dateRange: null });
                                    } else {
                                        onDateChange({ dateRange: `${dateStrings[0]} , ${dateStrings[1]}` });
                                    }
                                }}
                                on
                            />
                        </div>

                        {/* <UserListCard jobsList={emailContactListData?.data} /> */}
                        <SystemConfigurationWrapped>
                            <div className="row pb-3 ">
                                <div className="col-sm-6 p-sm-0">
                                    <Input
                                        prefix={<SearchOutlined style={{ width: '20px' }} />}
                                        allowClear
                                        placeholder="Search"
                                        value={search}
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                            if (!e.target.value) {
                                                onSearch("");
                                                setSearch("")
                                            }
                                        }}
                                        onPressEnter={() => onSearch(search)}
                                        style={{
                                            marginRight: '15px',
                                        }}
                                    />
                                </div>
                                <div className="col-sm-6 mt-3 mt-sm-0 text-end">
                                    <Button className="reject_btn" loading={exportLoading} onClick={exportToExcel}>Export <DownloadImage /></Button>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 p-sm-0">
                                    <Table
                                        loading={emailStatus === STATUSES.LOADING}
                                        dataSource={emailContactListData?.data ?? []}
                                        columns={columns}
                                        bordered={true}
                                        indentSize={33}
                                        pagination={false} // Disable pagination
                                        scroll={{ x: 1000 }}
                                    />
                                </div>
                            </div>

                        </SystemConfigurationWrapped>

                    </Col>
                </Row>
            </div>

            {
                <div className="featureBg__border p-2 d-flex justify-content-center w-100 pb-5">
                    <Pagination
                        current={emailContactListData?.meta.page ?? 1}
                        pageSize={emailContactListData?.meta.per_page ?? 10}
                        total={emailContactListData?.meta.total}
                        showSizeChanger={true}
                        onChange={paginationOnChange}
                        onShowSizeChange={paginationOnChange}
                    />

                </div>
            }
        </JobRequestStyle>

    );
};

export default EmailContactFormList;
