import React, { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/shared/GeneralComponents";
import { STATUSES } from "../../config/constants/defaultValues";
import {
  getJobDetailsByIdRequest,
  jobApprovalRejectAndAcceptRequest,
} from "../../redux/jobsSlice";
import { TabPanelDetailStyle } from "../style";

const JobDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobID } = useParams();

  const { status, jobDetails } = useSelector(
    (state) => state.jobsList
  );

  useEffect(() => {
    dispatch(getJobDetailsByIdRequest({ jobID: jobID }));
  }, [dispatch, jobID]);

  const onReject = (jobStatus) => {
    dispatch(
      jobApprovalRejectAndAcceptRequest({ jobID: jobID, jobStatus: jobStatus })
    ).then(() => {
      navigate(-1);
    });
  };
  return (
    <>
      {status === STATUSES.LOADING ? (
        <Loading />
      ) : status === STATUSES.ERROR ? (
        <div tip="Error" />
      ) : (
        <TabPanelDetailStyle>
          <div className="row w-100 mr-0">
            <div className="col-sm-12 justify-content-between d-flex align-items-center px-0 ">
              <div>
                <div className="padding-left-6 d-flex align-items-center">
                  <div
                    className="heading"
                    style={{
                      color: "#372354",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Jobs List
                  </div>
                  <div className="heading px-3" style={{ fontSize: "16px" }}>
                    {" "}
                    &#62;
                  </div>
                  <div className="heading_child " style={{ fontSize: "16px" }}>
                    {jobDetails?.job?.discipline.name ?? " "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-style">
            <div className="row space-between w-100">
              <div className="col-md-12 col-xs-12">
                <div className="d-flex align-items-center justify-content-start pl-3">
                  <div>
                    <h6 className="list-heading">
                      {jobDetails?.job?.discipline.name ?? " "}
                    </h6>

                    <p className="mb-0 sub-heading">
                      {jobDetails?.job?.location_detail?.name ?? ""}
                    </p>
                    <p className="mb-0 sub2-heading">
                      {jobDetails?.job?.salary}{" "}
                      <span>{jobDetails?.job?.salary_period}</span>
                    </p>
                  </div>
                </div>
                <hr style={{ marginTop: "20px" }} />
              </div>

            </div>

            <div className="row space-between">
              <div className="col-md-12 px-2">
                <h6 className="heading-title">Job Requirements</h6>

                <h6 className="test-p-list text-break">
                  {ReactHtmlParser(jobDetails?.job?.requirements ?? jobDetails?.job?.discipline?.requirements ?? "")}
                </h6>
              </div>
            </div>
            <div className="row space-between">
              <div className="col-md-12 px-2">
                <h6 className="heading-title">Job Benefits</h6>
                <h6 className="test-p-list text-break">
                  {ReactHtmlParser(jobDetails?.job?.benefits ?? jobDetails?.job?.discipline?.benefits ?? "")}
                </h6>
              </div>
            </div>
            <div className="row space-between">
              <div className="col-md-12 px-2">
                <h6 className="heading-title">Job Description</h6>
                <h6 className="test-p-list text-break">
                  {ReactHtmlParser(jobDetails?.job?.description ?? jobDetails?.job?.discipline?.description ?? "")}
                </h6>
              </div>
            </div>

          </div>
        </TabPanelDetailStyle>

      )}
    </>
  );
};
export default JobDetail;
