export const LOGIN_API = "/login";
export const DASHBOARD_API = "/admin/dashboard";
export const JOB_LIST_API = "/admin/jobs-list";
export const JOBS_API = "/admin/jobs/";
export const JOBS_STATE_TYPE_API = "/admin/state-types/";
export const JOBS_LOCATION_LIST_API = "/admin/location-list/";
export const JOBS_APPLIED_LIST_API = "/admin/job-applicant-list";
export const CONTACT_FORM_LIST_API = "/admin/contact-form-list";
export const EMAIL_CONTACT_FORM_LIST_API = "/admin/email-contact-form-list";
export const CONTENT_PAGES_API = "/admin/content-pages/";
export const GET_REVIEWS_API = "/admin/get-reviews";
export const FEATURE_JOB_UPDATE_API = "/admin/update-featured-job";
export const REFERRAL_JOB_UPDATE_API = "/admin/update-referral-job";
export const JOB_STATUS_UPDATE_API = "/admin/update-job-status";


export const JOB_APPROVAL_Request_API = "/admin/job-approval-request";
export const GET_CONTENT_MANAGEMENT_PAGES = "/admin/pages/";
export const CONTACT_PAGE_API = "/admin/contact-pages/1";
export const HOME_PAGE_API = "/admin/configurable-items/1";
export const GET_BLOG_LIST_API = "/admin/get-blogs";
export const GET_SINGLE_BLOG_API = "/admin/blogs/";
export const GET_BLOG_MANAGEMENT_LIST_API = "/admin/get-blog-page-sections";
export const GET_BLOG_MANAGEMENT_DETAIL_API = "/admin/blog-page-sections/";
export const BLOG_API = "/admin/blogs";
export const CATEGORY_API = "/admin/categories-list";
export const AUTO_COMPLETE_APPROVAL_API = "/admin/auto-complete-approvals";
export const AUTO_COMPLETE_ALL_READY_USED_API =
  "/admin/auto-complete-already-used/";
export const AUTO_COMPLETE_ACCEPT_REJECT_API =
  "/admin/auto-complete-approval-request";
export const GET_SYSTEM_CONFIGURATION_LIST_API = "/admin/setup-drop-downs";
export const GET_SYSTEM_CONFIGURATION_DETAIL_API = "/admin/drop-down-list-data";
export const POST_SYSTEM_CONFIGURATION_DETAIL_API =
  "/admin/drop-down-update-data";
export const GET_WEBSITE_USER_LIST_API = "/admin/users-list";
export const GET_WEBSITE_USER_DETAIL_API = "/admin/users/";
export const GET_HOSPITAL_TYPE_API = "/admin/hospital-type-list";
export const GET_LOCATION_AGAINST_HOSPITAL_TYPE_API = "/admin/location-list";
export const GET_REFERRALS_LIST_API = "/admin/referrals-list";
export const GET_DISCIPLINES_LIST_API = "/admin/disciplines-list";
export const POST_DISCIPLINES_LIST_API = "/admin/disciplines";
export const FEATURE_DISCIPLINE_UPDATE_API = "/admin/update-featured-discipline";
export const GET_STATES_LIST_API = "/admin/states-list";
export const POST_STATES_LIST_API = "/admin/states";
export const GET_HOSPITAL_TYPES_LIST_API = "/admin/hospital-types-list";
export const POST_HOSPITAL_TYPES_LIST_API = "/admin/hospital-types";
export const GET_LOCATIONS_LIST_API = "/admin/locations-list";
export const POST_LOCATIONS_LIST_API = "/admin/locations";
export const GET_TESTIMONIALS_LIST_API = "/admin/testimonials-list";
export const POST_TESTIMONIALS_LIST_API = "/admin/testimonials";
