import { AutoCompleteRequestWrapped } from "./style";
import { Pagination } from "antd";
import AutoCompleteRequestCard from "../components/shared/AutoCompleteRequestCard";
import SearchInput from "../components/form/SearchInput";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAutoCompleteApprovalRequest } from "../redux/autoCompleteApprovalRequestSlice";
import { STATUSES } from "../config/constants/defaultValues";
import Loading from "../components/shared/GeneralComponents";

const AutoCompleteRequest = () => {
  const dispatch = useDispatch();
  const { status, autoCompleteData, error } = useSelector(
    (state) => state.autoCompleteApproval
  );
  const [search, setSearch] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(getAutoCompleteApprovalRequest({}));
  }, [dispatch]);

  const paginationOnChange = (page) => {
    setPageNumber(page)
    dispatch(
      getAutoCompleteApprovalRequest({ generalSearch: search, page: page })
    );
  };

  const onSearch = (generalSearch) => {
    setSearch(generalSearch);
    dispatch(getAutoCompleteApprovalRequest({ generalSearch, page: 1 }));
  };
  return (
    <>
      {status === STATUSES.LOADING ? (
        <Loading />
      ) : status === STATUSES.ERROR ? (
        <div>{error}</div>
      ) : (
        <AutoCompleteRequestWrapped>
          <div className="layout-content">
            <div className="row rowgap-vbox">
              <div className="col-md-12 mb-24 ">
                <div className="project-ant pb-3">
                  <div className="padding-left-6">
                    <div className="heading">Auto Complete Request</div>
                  </div>

                  <div>
                    <SearchInput setSearch={setSearch} search={search} onSearch={onSearch}  />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="auto_section">
                      <AutoCompleteRequestCard
                        autoCompleteList={autoCompleteData?.data ?? []} search={search} pageNumber={pageNumber}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            <div className="featureBg__border p-2 d-flex justify-content-center w-100 pb-5">
              <Pagination
                current={autoCompleteData?.meta.page ?? 1}
                total={autoCompleteData?.meta.total}
                showSizeChanger={false}
                onChange={paginationOnChange}
              />{" "}
            </div>
          }
        </AutoCompleteRequestWrapped>
      )}
    </>
  );
};

export default AutoCompleteRequest;
