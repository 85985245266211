import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, Pagination, Row, Table, Typography } from "antd";
import Search from "antd/lib/input/Search";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import { ReactComponent as DownloadImage } from "../../assets/images/download.svg";
import RangePickers from "../../components/DatePicker/RangePickers";
import UserDetailModal from "../../components/modal/WebsiteUserDetailModel";
import { errorNotification } from "../../components/notifications/alertNotification";
import { STATUSES } from "../../config/constants/defaultValues";
import { getAllUsersExportDataRequest, getWebsiteUserListRequest } from "../../redux/userSlice";
import { JobRequestStyle } from "../style";
import { SystemConfigurationWrapped } from "../systemConfiguration/style";
const { Text } = Typography;



const UserList = () => {
    const dispatch = useDispatch();
    const { status, websiteUserData, error } = useSelector(
        (state) => state.userList
    );
    const [search, setSearch] = useState();
    const [exportLoading, setExportLoading] = useState(false);
    const [modalData, setModelData] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dateRange, setDateRange] = useState("");


    useEffect(() => {
        dispatch(getWebsiteUserListRequest({}));
    }, [dispatch]);


    const paginationOnChange = (data, pageSize) => {
        dispatch(
            getWebsiteUserListRequest({
                generalSearch: search, page: data, pageSize: pageSize, dateRange: dateRange,
            })
        );
    };

    const onDateChange = ({ dateRange }) => {
        setDateRange(dateRange);
        dispatch(getWebsiteUserListRequest({
            generalSearch: search,
            dateRange: dateRange,
        }));
    };

    const onSearch = (generalSearch) => {
        setSearch(generalSearch);
        dispatch(getWebsiteUserListRequest({
            generalSearch, dateRange: dateRange,
        }));
    };


    const exportToExcel = async () => {
        setExportLoading(true);
        try {
            const data = await dispatch(getAllUsersExportDataRequest({
                generalSearch: search, dateRange: dateRange,
            }));

            let filtered = [...data.payload];

            const worksheet = XLSX.utils.json_to_sheet(
                filtered.map((record) => ({
                    "First Name": record.first_name,
                    "Last Name": record.last_name,
                    Discipline: record.discipline.name,
                    email: record.email,
                    "Phone Number": record.phone,
                    "email Verified": record.email_verified_at ?? "No",
                    "Created At": record.created_at,
                    updated_at: record.updated_at,
                    resume: record.resume,
                    licenses: record.user_licenses.map((license) => license.license).join(',\n '),
                }))
            );

            const currentDateTimeString = moment(new Date()).format('MM-DD-YYYY-hh:mm-A');
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, `imperial-AllJobs-DataSheet-${currentDateTimeString}.xlsx`);
        } catch (error) {
            errorNotification('Error while fetching or processing data:', error)
        } finally {
            setExportLoading(false); // Ensure that loading indicator is turned off, even in case of an error
        }

    };

    const showModal = (record) => {
        setModelData(record)
        setIsModalOpen(true);
    };

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            width: "5%",
            // ...getColumnSearchProps('id'),
        },
        {
            title: "First Name",
            dataIndex: "first_name",
            ellipsis: true,
        },
        {
            title: "Last Name",
            dataIndex: "last_name",
            ellipsis: true,
        },
        {
            title: "Discipline",
            dataIndex: "discipline",
            ellipsis: true,

            render: (text) => <Text strong>{text.name}</Text>,
        },
        {
            title: "Email",
            dataIndex: "email",
            ellipsis: true,
            width: "20%",

        },

        {
            title: "Phone Number",
            dataIndex: "phone",
            ellipsis: true,

        },

        {
            title: "Updated Date",
            dataIndex: "updated_at",
            ellipsis: true,
            // render: (text) => moment(text).format('MM-DD-YYYY'),
            sorter: (a, b) => {
                // Convert the date strings to Date objects for comparison
                const dateA = new Date(a.updated_at);
                const dateB = new Date(b.updated_at);

                return dateA - dateB; // To sort in ascending order
            },
        },


        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (text, record) => (
                //<Space>
                <span style={{
                    color: '#372354',
                    cursor: 'pointer',
                }}
                    className='job_view'
                    onClick={() => showModal(record)}
                >
                    <UserOutlined />
                </span>
                //  </Space>
            ),
        },
    ];

    return (
        <JobRequestStyle>
            <div className="layout-content">
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                        <div className="row  pb-3 pb-sm-5">
                            <div className="col-sm-9">
                                <div className="heading">Website Users List</div>
                            </div>
                            {/* <div className="col-sm-3">
                                <button className="border-0 mt-2 mt-sm-0">
                                    <Link
                                        className="approved_btn_w px-2"
                                    // to="/job-create"
                                    >
                                        Create New User
                                    </Link>
                                </button>
                            </div> */}
                        </div>
                        <div className="col-xl-5 col-lg-7 col-md-5 col-sm-12">
                            <RangePickers
                                name="Date_Posted"
                                req={false}
                                labelText="Date Range"
                                onChange={(_, dateStrings) => {
                                    if (dateStrings[0] == "") {
                                        onDateChange({ dateRange: null });
                                    } else {
                                        onDateChange({ dateRange: `${dateStrings[0]} , ${dateStrings[1]}` });
                                    }
                                }}
                                on
                            />
                        </div>

                        {/* <UserListCard jobsList={websiteUserData?.data} /> */}
                        <SystemConfigurationWrapped>
                            <div className="row pb-3 justify-content-end">
                                <div className="col-sm-6 d-flex align-items-center">
                                    <Search
                                        onSearch={onSearch}
                                        onChange={(value) => setSearch(value.value)}
                                        prefix={<SearchOutlined width={"20px"} />}
                                        allowClear
                                        placeholder="Search"
                                        value={search}
                                        style={{
                                            marginRight: "15px"
                                        }}
                                    />
                                </div>
                                <div className="col-sm-6 mt-3 mt-sm-0 text-end">
                                    <Button className="reject_btn" loading={exportLoading} onClick={exportToExcel}>Export <DownloadImage /></Button>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 p-sm-0">
                                    <Table
                                        loading={status === STATUSES.LOADING}
                                        dataSource={websiteUserData?.data ?? []}
                                        columns={columns}
                                        bordered={true}
                                        indentSize={33}
                                        pagination={false} // Disable pagination
                                        scroll={{ x: 1000 }}
                                    />
                                </div>
                            </div>

                        </SystemConfigurationWrapped>

                    </Col>
                </Row>
            </div>

            {
                <div className="featureBg__border p-2 d-flex justify-content-center w-100 pb-5">
                    <Pagination
                        current={websiteUserData?.meta.page ?? 1}
                        pageSize={websiteUserData?.meta.per_page ?? 10}
                        total={websiteUserData?.meta.total}
                        showSizeChanger={true}
                        onChange={paginationOnChange}
                        onShowSizeChange={paginationOnChange}
                    />

                </div>
            }
            <UserDetailModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                modalData={modalData}
            />
        </JobRequestStyle>

    );
};

export default UserList;
