// export const SERVER_URL = "http://192.168.18.23:86/api";
export const SERVER_URL = "https://live.imperiallocum.com/api";
// export const SERVER_URL = "https://dev.jumprecruiter.com/api";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  ERROR: "error",
  LOADED: "error",
});
export const BannerImage = require("../../assets/images/bannerImage.png");

export const USER_TYPE = Object.freeze({
  SUPER_ADMIN: "1",
  SYSTEM_ADMIN: "4",
  JUMP_ADMIN: "5",
  BLOG_ADMIN: "6",
  NONE: "0",
});

export const REQUEST_METHOD = Object.freeze({
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
});
export const CONTENT_PAGES = Object.freeze({
  HOME_PAGE: 1,
  WHAT_LOCUM_TENENS_PAGE: 2,
  WHY_WORK_LOCUM_TENENS_PAGE: 3,
  LOCUM_TENENS_PAGE: 4,
  IMPORTANCE_LOCUM_TENENS_PAGE: 5,
  ABOUT_US_PAGE: 6,
  JOB_LIST: 7,
  CONTACT_US_PAGE: 8,
  LOGIN_PAGE: 9,
  REGISTER_PAGE: 10,
  FORGOT_PASSWORD_PAGE: 11,
  RESET_PASSWORD_PAGE: 12,
  SUCCESS_RESET_PASSWORD_PAGE: 13,
  TERMS_PAGE: 14,
  PRIVACY_PAGE: 15,
  COOKIE_POLICY_PAGE: 16,
  WEBSITE_FOOTER: 17,
});
export const TableDataState = Object.freeze({
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3,
});
export const ImageUploadDefaults = Object.freeze({
  MAX_SIZE: 2097152,// 2MB
  ALLOWED_EXTENSIONS: ".png, .jpg, .jpeg, .svg, .webp"
});
