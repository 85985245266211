import { Form, Modal } from "antd";
import React, { useState } from "react";
import SaveButton from "../../../components/button/saveButton";
import BaseInput from "../../../components/form/BaseInput";
import { createConfigurationalStateRequest, getConfigurationalStateListRequest } from "../../../redux/systemConfigurationSlice";
import { useDispatch } from "react-redux";
import { STATUSES } from "../../../config/constants/defaultValues";

const StateCreateModal = ({
    isModalOpen,
    setIsModalOpen,
}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    //Save Button
    const onSave = async (values) => {
        setIsLoading(true);


        await dispatch(createConfigurationalStateRequest({
            values: {
                ...values,
                status: "1"
            },
        })).then((data) => {
            if (data.payload.status === 200) {
                dispatch(getConfigurationalStateListRequest({}));
                setIsModalOpen(false);
            }
            setIsLoading(false);

        })
    }
    return (
        <>
            <Modal
                className="modal-styles"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                // width={600}
                maskClosable={false}
                centered={true}
                title={false}
            >
                <div className="row">
                    <div className="col-md-12">
                        <h5 className="table-heading mb-0 ">Create State</h5>
                    </div>
                </div>
                <div className="modal-content">
                    <Form layout="vertical" className="pt-3" onFinish={onSave} form={form}>
                        <div className="row">
                            <div className="col-sm-12 d-flex justify-content-between align-items-start">
                                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <BaseInput
                                        inputIcon={<i className="bi bi-pencil icon_style"></i>}
                                        labelText="State"
                                        name="name"
                                        placeText="Add State"
                                    />
                                </div>
                                <SaveButton loading={isLoading ? STATUSES.LOADING : false} title="Save" />
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    );
};
export default StateCreateModal;
