import { DeleteOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Pagination,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import Search from "antd/lib/input/Search";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import RangePickers from "../../../components/DatePicker/RangePickers";
import { errorNotification } from "../../../components/notifications/alertNotification";
import { STATUSES } from "../../../config/constants/defaultValues";
import {
  deleteConfigurationalDisciplineRequest,
  getConfigurationalDisciplineListExportDataRequest,
  getConfigurationalDisciplineListRequest,
} from "../../../redux/systemConfigurationSlice";
import { ReactComponent as DownloadImage } from "../../../assets/images/download.svg";
import { JobRequestStyle } from "../../style";
import { SystemConfigurationWrapped } from "../style";
import DisciplineModal from "./DisciplineDetailModal";
import DisciplineEditModal from "./DisciplineEditModal";
import DisciplineCreateModal from "./DisciplineCreateModal";
import { disciplineFeatureChangeRequest } from "../../../redux/appDataSlice";

const DisciplineConfiguration = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { disciplineStatus, disciplineData, disciplineError } = useSelector(
    (state) => state.systemConfiguration
  );
  const [search, setSearch] = useState();
  const [exportLoading, setExportLoading] = useState(false);
  const [modalData, setModelData] = useState();
  const [editModalData, setEditModelData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState("");
  const [selectedValues, setSelectedValues] = useState(null);
  const [disciplinesList, setDisciplinesList] = useState([]);

  useEffect(() => {
    if (disciplineData) {
      setDisciplinesList(disciplineData.data);
    }
  }, [disciplineData]);

  useEffect(() => {
    if (disciplineStatus !== STATUSES.LOADED) {
      dispatch(getConfigurationalDisciplineListRequest({}));
    }
  }, [dispatch]);

  const paginationOnChange = (data, pageSize) => {
    dispatch(
      getConfigurationalDisciplineListRequest({
        generalSearch: search,
        page: data,
        pageSize: pageSize,
        dateRange: dateRange,
        isFeatured: selectedValues?.featured === true ? 1 : 0,
      })
    );
  };

  const onSearch = (generalSearch) => {
    setSearch(generalSearch);
    dispatch(
      getConfigurationalDisciplineListRequest({
        generalSearch,
        dateRange: selectedValues?.dateRange,
        isFeatured: selectedValues?.featured === true ? 1 : 0,
      })
    );
  };

  const onDataChange = ({ dateRange, featured }) => {
    // Copy the existing selectedValues into a new object
    const newSelectedValues = { ...selectedValues };

    if (dateRange !== undefined) {
      newSelectedValues.dateRange = dateRange;
    }

    if (featured !== undefined) {
      newSelectedValues.featured = featured;
    }

    setSelectedValues(newSelectedValues);

    dispatch(
      getConfigurationalDisciplineListRequest({
        dateRange: newSelectedValues?.dateRange,
        isFeatured: newSelectedValues?.featured === true ? 1 : 0,
      })
    );
  };

  const exportToExcel = async () => {
    setExportLoading(true);
    try {
      const data = await dispatch(
        getConfigurationalDisciplineListExportDataRequest({
          generalSearch: search,
          dateRange: dateRange,
        })
      );

      let filtered = [...data.payload];

      const worksheet = XLSX.utils.json_to_sheet(
        filtered.map((record) => ({
          Discipline: record.name,
          updated_at: record.updated_at,
          // requirements: record.requirements,
          // benefits: record.benefits,
          // description: record.description,
          is_featured: record.is_featured,
        }))
      );

      const currentDateTimeString = moment(new Date()).format(
        "MM-DD-YYYY-hh:mm-A"
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `imperial-disciplines-DataSheet-${currentDateTimeString}.xlsx`
      );
    } catch (error) {
      errorNotification("Error while fetching or processing data:", error);
    } finally {
      setExportLoading(false); // Ensure that loading indicator is turned off, even in case of an error
    }
  };

  const showModal = (record) => {
    setModelData(record);
    setIsModalOpen(true);
  };

  const showEditModal = (record) => {
    setEditModelData(record);
    setIsEditModalOpen(true);
  };
  const showCreateModal = () => {
    setIsCreateModalOpen(true);
  };
  const deleteDiscipline = (id) => {
    dispatch(deleteConfigurationalDisciplineRequest(id));
    dispatch(getConfigurationalDisciplineListRequest({}));
  };
  const handleFeatureSwitchChange = (checked, record) => {
    disciplineFeatureChangeRequest({
      disciplineId: record.id,
      isFeatured: checked,
    });
    const updatedData = [...disciplinesList]; // Create a shallow copy of the data array
    const index = updatedData.findIndex((item) => item.id === record.id);
    if (index !== -1) {
      updatedData[index] = {
        ...updatedData[index],
        is_featured: checked ? 1 : 0,
      };
      setDisciplinesList(updatedData);
    }
  };

  const clearSelect = () => {
    form.resetFields();
    setSearch(null);
    onDataChange({ dateRange: null, featured: null });
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: "10%",
      // ...getColumnSearchProps('id'),
    },
    {
      title: "Discipline",
      dataIndex: "name",
      width: "40%",
      ellipsis: true,

      // render: (text) => <Text strong>{text.name}</Text>,
    },
    {
      title: "Featured",
      dataIndex: "is_featured",
      ellipsis: true,
      width: "10",
      render: (isFeatured, record) => (
        <Switch
          checked={isFeatured === 1}
          // size="small"
          onChange={(checked) => handleFeatureSwitchChange(checked, record)}
        />
      ),
    },
    {
      title: "Updated Date",
      dataIndex: "updated_at",
      ellipsis: true,
      // render: (text) => moment(text).format('MM-DD-YYYY'),
      sorter: (a, b) => {
        // Convert the date strings to Date objects for comparison
        const dateA = new Date(a.updated_at);
        const dateB = new Date(b.updated_at);

        return dateA - dateB; // To sort in ascending order
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: "15%",
      render: (text, record) => (
        <Space>
          <span
            style={{
              color: "#372354",
              cursor: "pointer",
            }}
            className="job_view"
            onClick={() => showModal(record)}
          >
            <EyeOutlined />
          </span>
          <span
            style={{
              color: "#372354",
              cursor: "pointer",
            }}
            className="job_view"
            onClick={() => showEditModal(record)}
          >
            <i class="bi bi-pencil" />
          </span>
          <span
            style={{
              color: "#962020",
              cursor: "pointer",
            }}
            className="job_view"
          >
            <Popconfirm
              Popconfirm
              title="Are you sure you want to delete this?"
              okText="Delete"
              placement="top"
              onConfirm={() => deleteDiscipline(record.id)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </span>
        </Space>
      ),
    },
  ];

  return (
    <JobRequestStyle>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="row  pb-3 pb-sm-5">
              <div className="col-sm-9">
                <div className="heading">Discipline List</div>
              </div>
              <div className="col-sm-3">
                <button
                  className="approved_btn_w px-2 border-0 mt-2 mt-sm-0"
                  onClick={() => showCreateModal()}
                >
                  Create New
                </button>
              </div>
            </div>
            <Form form={form}>
              <div className="row">
                <div className="col-xl-5 col-lg-6 col-md-5 col-sm-12 ">
                  <RangePickers
                    name="Date_Posted"
                    req={false}
                    labelText="Date Posted"
                    onChange={(_, dateStrings) => {
                      if (dateStrings[0] == "") {
                        onDataChange({ dateRange: null });
                      } else {
                        onDataChange({
                          dateRange: `${dateStrings[0]} , ${dateStrings[1]}`,
                        });
                      }
                    }}
                    on
                  />
                </div>
                <div className="col-xl-5 col-lg-6 col-md-5 col-sm-12 d-flex align-items-center pt-1">
                  <Form.Item
                    name="featured"
                    className="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                  >
                    <Checkbox
                      value={selectedValues?.featured}
                      onChange={(value) => {
                        onDataChange({ featured: value.target.checked });
                      }}
                    >
                      Featured
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className="col-sm-12 text-end mb-4 ">
                  <div className="d-flex justify-content-start">
                    <Button className="reject_btn" onClick={clearSelect}>
                      Clear All Filters
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
            {/* <UserListCard jobsList={disciplineData?.data} /> */}
            <SystemConfigurationWrapped>
              <div className="row pb-3 ">
                <div className="col-sm-6 p-sm-0">
                  <Search
                    onSearch={onSearch}
                    onChange={(value) => setSearch(value.value)}
                    prefix={<SearchOutlined width={"20px"} />}
                    allowClear
                    placeholder="Search"
                    value={search}
                    style={{
                      marginRight: "15px",
                    }}
                  />
                </div>
                <div className="col-sm-6 mt-3 mt-sm-0 text-end">
                  <Button
                    className="reject_btn"
                    loading={exportLoading}
                    onClick={exportToExcel}
                  >
                    Export <DownloadImage />
                  </Button>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 p-sm-0">
                  <Table
                    loading={disciplineStatus === STATUSES.LOADING}
                    dataSource={disciplinesList ?? []}
                    columns={columns}
                    bordered={true}
                    indentSize={33}
                    pagination={false} // Disable pagination
                    scroll={{ x: 600 }}
                  />
                </div>
              </div>
            </SystemConfigurationWrapped>
          </Col>
        </Row>
      </div>

      {
        <div className="featureBg__border p-2 d-flex justify-content-center w-100 pb-5">
          <Pagination
            current={disciplineData?.meta.page ?? 1}
            pageSize={disciplineData?.meta.per_page ?? 10}
            total={disciplineData?.meta.total}
            showSizeChanger={true}
            onChange={paginationOnChange}
            onShowSizeChange={paginationOnChange}
          />
        </div>
      }
      <DisciplineModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalData={modalData}
      />
      <DisciplineEditModal
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
        modalData={editModalData}
      />
      <DisciplineCreateModal
        isModalOpen={isCreateModalOpen}
        setIsModalOpen={setIsCreateModalOpen}
      />
    </JobRequestStyle>
  );
};

export default DisciplineConfiguration;
