import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorNotification, successNotification } from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import { GET_STATES_LIST_API, GET_DISCIPLINES_LIST_API, GET_SYSTEM_CONFIGURATION_DETAIL_API, GET_SYSTEM_CONFIGURATION_LIST_API, GET_WEBSITE_USER_LIST_API, POST_STATES_LIST_API, POST_DISCIPLINES_LIST_API, POST_SYSTEM_CONFIGURATION_DETAIL_API, GET_HOSPITAL_TYPES_LIST_API, GET_LOCATIONS_LIST_API, POST_HOSPITAL_TYPES_LIST_API, POST_LOCATIONS_LIST_API, POST_TESTIMONIALS_LIST_API, GET_TESTIMONIALS_LIST_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";


export const getSystemConfigurationListRequest = createAsyncThunk(
    "systemConfiguration/getSystemConfigurationListRequest",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_SYSTEM_CONFIGURATION_LIST_API,
                REQUEST_METHOD.GET,
                {},
                true
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getSystemConfigurationDetailRequest = createAsyncThunk(
    "systemConfiguration/getSystemConfigurationDetailRequest",
    async ({ modelData }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_SYSTEM_CONFIGURATION_DETAIL_API,
                REQUEST_METHOD.POST,
                {
                    model_path: modelData.model_path,
                    model_name: modelData.model_name,
                    status: 1,
                },
                true
            );
            if (response.status === 200) {

                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const getConfigurationalDisciplineListRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalDisciplineListRequest",
    async ({ generalSearch, page, pageSize, dateRange, isFeatured }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_DISCIPLINES_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": page ?? 1, "per_page": pageSize ?? 30, "export": "0" },
                    "query": { "status": "1", "generalSearch": generalSearch ?? "", "dateRange": dateRange ?? "", "featured": isFeatured, }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getConfigurationalDisciplineListExportDataRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalDisciplineListExportDataRequest",
    async ({ generalSearch, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_DISCIPLINES_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": 1, "per_page": 30, "export": "1" },
                    "query": { "status": "1", "generalSearch": generalSearch, "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const createConfigurationalDisciplineRequest = createAsyncThunk(
    "systemConfiguration/createConfigurationalDisciplineRequest",
    async ({ values }, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_DISCIPLINES_LIST_API,
                REQUEST_METHOD.POST,
                values,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const editConfigurationalDisciplineRequest = createAsyncThunk(
    "systemConfiguration/editConfigurationalDisciplineRequest",
    async ({ values }, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_DISCIPLINES_LIST_API + "/" + values.id,
                REQUEST_METHOD.PUT,
                values,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const deleteConfigurationalDisciplineRequest = createAsyncThunk(
    "systemConfiguration/deleteConfigurationalDisciplineRequest",
    async (id, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_DISCIPLINES_LIST_API + "/" + id,
                REQUEST_METHOD.DELETE,
                null,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const getConfigurationalStateListRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalStateListRequest",
    async ({ generalSearch, page, pageSize, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_STATES_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": page ?? 1, "per_page": pageSize ?? 30, "export": "0" },
                    "query": { "status": "1", "generalSearch": generalSearch ?? "", "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getConfigurationalStateListExportDataRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalStateListExportDataRequest",
    async ({ generalSearch, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_STATES_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": 1, "per_page": 30, "export": "1" },
                    "query": { "status": "1", "generalSearch": generalSearch, "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const createConfigurationalStateRequest = createAsyncThunk(
    "systemConfiguration/createConfigurationalStateRequest",
    async ({ values }, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_STATES_LIST_API,
                REQUEST_METHOD.POST,
                values,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const editConfigurationalStateRequest = createAsyncThunk(
    "systemConfiguration/editConfigurationalStateRequest",
    async ({ values }, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_STATES_LIST_API + "/" + values.id,
                REQUEST_METHOD.PUT,
                values,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const deleteConfigurationalStateRequest = createAsyncThunk(
    "systemConfiguration/deleteConfigurationalStateRequest",
    async (id, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_STATES_LIST_API + "/" + id,
                REQUEST_METHOD.DELETE,
                null,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const getConfigurationalHospitalTypeListRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalHospitalTypeListRequest",
    async ({ generalSearch, page, pageSize, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_HOSPITAL_TYPES_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": page ?? 1, "per_page": pageSize ?? 30, "export": "0" },
                    "query": { "status": "1", "generalSearch": generalSearch ?? "", "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getConfigurationalHospitalTypeListExportDataRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalHospitalTypeListExportDataRequest",
    async ({ generalSearch, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_HOSPITAL_TYPES_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": 1, "per_page": 30, "export": "1" },
                    "query": { "status": "1", "generalSearch": generalSearch, "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const createConfigurationalHospitalTypeRequest = createAsyncThunk(
    "systemConfiguration/createConfigurationalHospitalTypeRequest",
    async ({ values }, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_HOSPITAL_TYPES_LIST_API,
                REQUEST_METHOD.POST,
                values,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const editConfigurationalHospitalTypeRequest = createAsyncThunk(
    "systemConfiguration/editConfigurationalHospitalTypeRequest",
    async ({ values }, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_HOSPITAL_TYPES_LIST_API + "/" + values.id,
                REQUEST_METHOD.PUT,
                values,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const deleteConfigurationalHospitalTypeRequest = createAsyncThunk(
    "systemConfiguration/deleteConfigurationalHospitalTypeRequest",
    async (id, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_HOSPITAL_TYPES_LIST_API + "/" + id,
                REQUEST_METHOD.DELETE,
                null,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const getConfigurationalLocationListRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalLocationListRequest",
    async ({ generalSearch, page, pageSize, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_LOCATIONS_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": page ?? 1, "per_page": pageSize ?? 30, "export": "0" },
                    "query": { "status": "1", "generalSearch": generalSearch ?? "", "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getConfigurationalLocationListExportDataRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalLocationListExportDataRequest",
    async ({ generalSearch, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_LOCATIONS_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": 1, "per_page": 30, "export": "1" },
                    "query": { "status": "1", "generalSearch": generalSearch, "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const createConfigurationalLocationRequest = createAsyncThunk(
    "systemConfiguration/createConfigurationalLocationRequest",
    async ({ values }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_LOCATIONS_LIST_API,
                REQUEST_METHOD.POST,
                newData,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const editConfigurationalLocationRequest = createAsyncThunk(
    "systemConfiguration/editConfigurationalLocationRequest",
    async ({ values }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_LOCATIONS_LIST_API + "/" + values.id,
                REQUEST_METHOD.POST,
                newData,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const deleteConfigurationalLocationRequest = createAsyncThunk(
    "systemConfiguration/deleteConfigurationalLocationRequest",
    async (id, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_LOCATIONS_LIST_API + "/" + id,
                REQUEST_METHOD.DELETE,
                null,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const systemConfigurationPostRequest = createAsyncThunk(
    "systemConfiguration/systemConfigurationPostRequest",
    async (values, { rejectWithValue }) => {
        try {
            const response = await apiService(
                POST_SYSTEM_CONFIGURATION_DETAIL_API,
                REQUEST_METHOD.POST,
                values,
                true
            );

            if (response.status === 200) {
                successNotification(response.data.message);
                return true;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return false;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return false;
        }
    }
);

export const getConfigurationalTestimonialListRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalTestimonialListRequest",
    async ({ generalSearch, page, pageSize, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_TESTIMONIALS_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": page ?? 1, "per_page": pageSize ?? 30, "export": "0" },
                    "query": { "status": "1", "generalSearch": generalSearch ?? "", "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const getConfigurationalTestimonialListExportDataRequest = createAsyncThunk(
    "systemConfiguration/getConfigurationalTestimonialListExportDataRequest",
    async ({ generalSearch, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                GET_TESTIMONIALS_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": 1, "per_page": 30, "export": "1" },
                    "query": { "status": "1", "generalSearch": generalSearch, "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const createConfigurationalTestimonialRequest = createAsyncThunk(
    "systemConfiguration/createConfigurationalTestimonialRequest",
    async ({ values }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

    // async ({ values }, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_TESTIMONIALS_LIST_API,
                REQUEST_METHOD.POST,
                newData,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const editConfigurationalTestimonialRequest = createAsyncThunk(
    "systemConfiguration/editConfigurationalTestimonialRequest",
    async ({ values }, { rejectWithValue }) => {
        const newData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
            newData.append(key, value === undefined ? null : value);
        });

    // async ({ values }, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_TESTIMONIALS_LIST_API + "/" + values.testimonial_id,
                REQUEST_METHOD.POST,
                newData,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);
export const deleteConfigurationalTestimonialRequest = createAsyncThunk(
    "systemConfiguration/deleteConfigurationalTestimonialRequest",
    async (id, { rejectWithValue }) => {
        try {
            // const newData = new FormData();
            const response = await apiService(
                POST_TESTIMONIALS_LIST_API + "/" + id,
                REQUEST_METHOD.DELETE,
                null,
                true
            );
            if (response.status === 200) {
                successNotification(response.data.message);
                return response;
            }
            else {
                errorNotification(Object.values(response.data.errors));
                return response;
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

const SystemConfigurationSlice = createSlice({
    name: "systemConfiguration",
    initialState: {
        systemConfigurationListData: null,
        systemConfigurationListStatus: STATUSES.IDLE,
        systemConfigurationListError: null,
        // systemConfiguration Detail
        systemConfigurationDetailData: null,
        systemConfigurationDetailStatus: STATUSES.IDLE,
        systemConfigurationDetailError: null,
        //discipline SystemConfiguration
        disciplineStatus: STATUSES.IDLE,
        disciplineData: null,
        disciplineError: null,
        //state SystemConfiguration
        stateStatus: STATUSES.IDLE,
        stateData: null,
        stateError: null,
        //testimonial SystemConfiguration
        testimonialStatus: STATUSES.IDLE,
        testimonialData: null,
        testimonialError: null,
    },
    extraReducers: {
        [getSystemConfigurationListRequest.pending]: (state) => {
            state.systemConfigurationListStatus = STATUSES.LOADING;
        },
        [getSystemConfigurationListRequest.fulfilled]: (state, action) => {

            state.systemConfigurationListStatus = STATUSES.IDLE;
            state.systemConfigurationListData = action.payload;
        },
        [getSystemConfigurationListRequest.rejected]: (state, action) => {
            state.systemConfigurationListStatus = STATUSES.ERROR;
            state.systemConfigurationListError = action.payload;
        },
        [getSystemConfigurationDetailRequest.pending]: (state) => {
            state.systemConfigurationDetailStatus = STATUSES.LOADING;
        },
        [getSystemConfigurationDetailRequest.fulfilled]: (state, action) => {

            state.systemConfigurationDetailStatus = STATUSES.IDLE;
            state.systemConfigurationDetailData = action.payload;
        },
        [getSystemConfigurationDetailRequest.rejected]: (state, action) => {
            state.systemConfigurationDetailStatus = STATUSES.ERROR;
            state.systemConfigurationDetailError = action.payload;
        },

        [getConfigurationalDisciplineListRequest.pending]: (state) => {
            state.disciplineStatus = STATUSES.LOADING;
        },
        [getConfigurationalDisciplineListRequest.fulfilled]: (state, action) => {
            state.disciplineStatus = STATUSES.LOADED;
            state.disciplineData = action.payload;
        },
        [getConfigurationalDisciplineListRequest.rejected]: (state, action) => {
            state.disciplineStatus = STATUSES.ERROR;
            state.disciplineError = action.payload;
        },
        // State system configurational
        [getConfigurationalStateListRequest.pending]: (state) => {
            state.stateStatus = STATUSES.LOADING;
        },
        [getConfigurationalStateListRequest.fulfilled]: (state, action) => {
            state.stateStatus = STATUSES.LOADED;
            state.stateData = action.payload;
        },
        [getConfigurationalStateListRequest.rejected]: (state, action) => {
            state.stateStatus = STATUSES.ERROR;
            state.stateError = action.payload;
        },
        // Hospital Type system configurational
        [getConfigurationalHospitalTypeListRequest.pending]: (state) => {
            state.hospitalTypeStatus = STATUSES.LOADING;
        },
        [getConfigurationalHospitalTypeListRequest.fulfilled]: (state, action) => {
            state.hospitalTypeStatus = STATUSES.LOADED;
            state.hospitalTypeData = action.payload;
        },
        [getConfigurationalHospitalTypeListRequest.rejected]: (state, action) => {
            state.hospitalTypeStatus = STATUSES.ERROR;
            state.hospitalTypeError = action.payload;
        },
        // Location system configurational
        [getConfigurationalLocationListRequest.pending]: (state) => {
            state.locationStatus = STATUSES.LOADING;
        },
        [getConfigurationalLocationListRequest.fulfilled]: (state, action) => {
            state.locationStatus = STATUSES.LOADED;
            state.locationData = action.payload;
        },
        [getConfigurationalLocationListRequest.rejected]: (state, action) => {
            state.locationStatus = STATUSES.ERROR;
            state.locationError = action.payload;
        },
        // Testimonial system configurational
        [getConfigurationalTestimonialListRequest.pending]: (state) => {
            state.testimonialStatus = STATUSES.LOADING;
        },
        [getConfigurationalTestimonialListRequest.fulfilled]: (state, action) => {
            state.testimonialStatus = STATUSES.LOADED;
            state.testimonialData = action.payload;
        },
        [getConfigurationalTestimonialListRequest.rejected]: (state, action) => {
            state.testimonialStatus = STATUSES.ERROR;
            state.testimonialError = action.payload;
        },
    },
});
export default SystemConfigurationSlice.reducer;

