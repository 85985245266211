import {
  EyeOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Pagination, Row, Space, Spin, Switch, Table, Typography } from "antd";
import Search from "antd/lib/input/Search";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import { ReactComponent as DownloadImage } from "../../assets/images/download.svg";
import RangePickers from "../../components/DatePicker/RangePickers";
import { errorNotification } from "../../components/notifications/alertNotification";
import Selects from "../../components/select/Selects";
import Loading from "../../components/shared/GeneralComponents";
import { STATUSES } from "../../config/constants/defaultValues";
import { getDisciplineListRequest, getHospitalTypeListRequest, getLocationListRequest, getStateListRequest, jobFeatureChangeRequest, jobReferralChangeRequest, jobStatusChangeRequest } from "../../redux/appDataSlice";
import { getAllJobRequestDataRequest, getAllJobsExportDataRequest } from "../../redux/jobsSlice";
import { JobRequestStyle } from "../style";
import { SystemConfigurationWrapped } from "../systemConfiguration/style";


const { Text } = Typography;
const JobList = () => {
  const dispatch = useDispatch();
  const { status, jobsListData, error } = useSelector((state) => state.jobsList);
  const { DisciplineData, DisciplineStatus, StateData, StateStatus, HospitalTypeData,
    HospitalTypeStatus, LocationData,
    LocationStatus } = useSelector((state) => state.appData);
  const [search, setSearch] = useState();
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState(null);
  const [form] = Form.useForm();
  const [jobsList, setJobsList] = useState([]);

  useEffect(() => {
    if (jobsListData) {
      setJobsList(jobsListData.data);
    }
  }, [jobsListData]);

  useEffect(() => {
    // if (status === STATUSES.IDLE) {
    dispatch(
      getAllJobRequestDataRequest({}));
    // }

  }, [dispatch]);

  const paginationOnChange = (data, pageSize) => {
    dispatch(
      getAllJobRequestDataRequest({
        generalSearch: search,
        page: data,
        pageSize: pageSize,
        discipline: selectedValues?.discipline?.id,
        state: selectedValues?.state?.id,
        hospitalType: selectedValues?.hospitalType?.id,
        location: selectedValues?.location?.id,
        dateRange: selectedValues?.dateRange,
        status: selectedValues?.statusFilter === true ? 1 : 0,
        isReferral: selectedValues?.referral === true ? 1 : 0,
        isFeatured: selectedValues?.featured === true ? 1 : 0,
      })
    );
  };

  const onSearch = (generalSearch) => {
    setSearch(generalSearch);
    dispatch(getAllJobRequestDataRequest({
      generalSearch,
      page: 1,
      discipline: selectedValues?.discipline?.id,
      state: selectedValues?.state?.id,
      hospitalType: selectedValues?.hospitalType?.id,
      location: selectedValues?.location?.id,
      dateRange: selectedValues?.dateRange,
      status: selectedValues?.statusFilter === true ? 1 : 0,
      isReferral: selectedValues?.referral === true ? 1 : 0,
      isFeatured: selectedValues?.featured === true ? 1 : 0,
    }));
  };

  const onDataChange = ({ discipline, state, hospitalType, location, dateRange, statusFilter, referral, featured }) => {

    // Copy the existing selectedValues into a new object
    const newSelectedValues = { ...selectedValues };

    // Update only the  property in the new object
    if (discipline !== undefined) {
      newSelectedValues.discipline = discipline;
    }
    if (state !== undefined) {
      newSelectedValues.state = state;
      newSelectedValues.hospitalType = null;
      newSelectedValues.location = null;
      form.resetFields(['hospital_id', 'location_id']);
    }

    if (hospitalType !== undefined) {
      newSelectedValues.hospitalType = hospitalType;
      newSelectedValues.location = null;
      form.resetFields(['location_id']);

    }

    if (location !== undefined) {
      newSelectedValues.location = location;
    }

    if (dateRange !== undefined) {
      newSelectedValues.dateRange = dateRange;
    }

    if (statusFilter !== undefined) {
      newSelectedValues.statusFilter = statusFilter;
    }
    if (referral !== undefined) {
      newSelectedValues.referral = referral;
    }
    if (featured !== undefined) {
      newSelectedValues.featured = featured;
    }

    setSelectedValues(newSelectedValues);

    dispatch(getAllJobRequestDataRequest({
      generalSearch: search,
      discipline: newSelectedValues?.discipline?.id,
      state: newSelectedValues?.state?.id,
      hospitalType: newSelectedValues?.hospitalType?.id,
      location: newSelectedValues?.location?.id,
      dateRange: newSelectedValues?.dateRange,
      status: newSelectedValues?.statusFilter === true ? 1 : 0,
      isReferral: newSelectedValues?.referral === true ? 1 : 0,
      isFeatured: newSelectedValues?.featured === true ? 1 : 0,
    }));
  };

  const clearSelect = () => {
    form.resetFields();
    setSearch(null);
    onDataChange({ discipline: null, state: null, hospitalType: null, location: null, dateRange: null, statusFilter: null, referral: null, featured: null });
  };

  const exportToExcel = async () => {
    setExportLoading(true);
    try {
      const data = await dispatch(getAllJobsExportDataRequest({
        generalSearch: search,
        discipline: selectedValues?.discipline?.id,
        state: selectedValues?.state?.id,
        hospitalType: selectedValues?.hospitalType?.id,
        location: selectedValues?.location?.id,
        dateRange: selectedValues?.dateRange,
        status: selectedValues?.statusFilter === true ? 1 : 0,
        isReferral: selectedValues?.referral === true ? 1 : 0,
        isFeatured: selectedValues?.featured === true ? 1 : 0,
      }));

      let filtered = [...data.payload];

      const worksheet = XLSX.utils.json_to_sheet(
        filtered.map((record) => ({
          Discipline: record.discipline.name,
          Location: record.location_detail.name,
          Salary: "$" + record.salary,
          "salary_period": record.salary_period,
          State: record.state.name,
          "Hospital Type": record.hospital_type.name,
          Shift: record.shift,
          is_featured: record.is_featured,
          is_referral: record.is_referral,
          "Job Status": record.status,
          "Start Date": record.start_date,
          "Last Apply Date": record.last_apply_date,
          updated_at: record.updated_at,
          requirements: record.requirements ?? record.discipline.requirements,
          benefits: record.benefits ?? record.discipline.benefits,
          description: record.description ?? record.discipline.description,
        }))
      );

      const currentDateTimeString = moment(new Date()).format('MM-DD-YYYY-hh:mm-A');
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, `imperial-AllJobs-DataSheet-${currentDateTimeString}.xlsx`);
    } catch (error) {
      errorNotification('Error while fetching or processing data:', error)
    } finally {
      setExportLoading(false); // Ensure that loading indicator is turned off, even in case of an error
    }

  };

  const handleFeatureSwitchChange = (checked, record) => {
    jobFeatureChangeRequest({ jobId: record.id, isFeatured: checked })
    const updatedData = [...jobsList]; // Create a shallow copy of the data array
    const index = updatedData.findIndex(item => item.id === record.id);

    if (index !== -1) {
      updatedData[index] = { ...updatedData[index], is_featured: checked ? 1 : 0 };
      setJobsList(updatedData);
    }
  };
  const handleReferralSwitchChange = (checked, record) => {
    jobReferralChangeRequest({ jobId: record.id, isReferral: checked })

    const updatedData = [...jobsList]; // Create a shallow copy of the data array
    const index = updatedData.findIndex(item => item.id === record.id);

    if (index !== -1) {
      updatedData[index] = { ...updatedData[index], is_referral: checked ? 1 : 0 };
      setJobsList(updatedData);
    }
  };
  const handleJobStatusSwitchChange = (checked, record) => {
    jobStatusChangeRequest({ jobId: record.id, status: checked })
    const updatedData = [...jobsList]; // Create a shallow copy of the data array
    const index = updatedData.findIndex(item => item.id === record.id);

    if (index !== -1) {
      updatedData[index] = { ...updatedData[index], status: checked ? 1 : 0 };
      setJobsList(updatedData);
    }
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      // ...getColumnSearchProps('id'),
    },
    {
      title: "Discipline",
      dataIndex: "discipline",
      ellipsis: true,
      width: 210,
      render: (text) => <Text strong>{text.name}</Text>,
    },

    {
      title: "Location",
      dataIndex: "location_detail",
      ellipsis: true,
      width: 240,
      render: (text) => <span>{text.name}</span>,
    },

    {
      title: "Price",
      dataIndex: "salary",
      ellipsis: true,
      width: 95,
      render: (text) => <span>{"$" + text}</span>,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      ellipsis: true,
      width: 118,
      // render: (text) => moment(text).format('MM-DD-YYYY'),
      sorter: (a, b) => {
        // Convert the date strings to Date objects for comparison
        const dateA = new Date(a.updated_at);
        const dateB = new Date(b.updated_at);
        return dateA - dateB; // To sort in ascending order
      },
    },
    {
      title: "Featured",
      dataIndex: "is_featured",
      ellipsis: true,
      width: 85,
      render: (isFeatured, record) => (
        <Switch checked={isFeatured === 1}
          // size="small"
          onChange={(checked) => handleFeatureSwitchChange(checked, record)} />
      ),
    },
    {
      title: "Referral",
      dataIndex: "is_referral",
      ellipsis: true,
      width: 85,
      render: (isFeatured, record) => (
        <Switch checked={isFeatured === 1}
          // size="small"
          onChange={(checked) => handleReferralSwitchChange(checked, record)} />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      ellipsis: true,
      width: 85,
      render: (isFeatured, record) => (
        <Switch checked={isFeatured === 1}
          // size="small"
          onChange={(checked) => handleJobStatusSwitchChange(checked, record)} />
      ),
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      width: 85,

      render: (text, record) => (
        <Space>
          <Link
            className='job_view'
            to={`/job-list/Job-details/${record?.id ?? ""}`}
          >
            <EyeOutlined />
          </Link>
          <Link
            className='view_btn'
            style={{ marginLeft: "10px" }}
            to={`/job-list/job-edit/${record?.id ?? ""}`}
          >
            <i class='bi bi-pencil'></i>
          </Link>
        </Space>
      ),
    },
  ];
  const getDisciplineData = () => {
    if (DisciplineStatus === STATUSES.IDLE) {
      dispatch(getDisciplineListRequest());
    }

  };
  const getStateData = () => {
    if (StateStatus === STATUSES.IDLE) {
      dispatch(getStateListRequest());
    }
  };
  const getHospitalTypeData = () => {
    if (selectedValues?.state?.id) {
      dispatch(getHospitalTypeListRequest({ state: selectedValues.state.id }));
    } else {
      errorNotification("Please Select State First")
    }

  };
  const getLocationData = () => {
    if (selectedValues?.state?.id && selectedValues?.hospitalType?.id) {
      dispatch(getLocationListRequest({ state: selectedValues.state.id, hospitalType: selectedValues?.hospitalType?.id }));
    } else {
      errorNotification("Please Select State and Hospital-Type First")
    }
  };
  // is_featured:0
  // is_referral:0
  return (
    <>
      <JobRequestStyle>
        <div className="layout-content">
          <Row className="rowgap-vbox" gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
              <div className="row pb-3 pb-sm-5">
                <div className="col-sm-9">
                  <div className="heading">Jobs List</div>
                </div>
                <div className="col-sm-3">
                  <button className="border-0 mt-2 mt-sm-0">
                    <Link
                      className="approved_btn_w px-2"
                      to="/job-create"
                    >
                      Create a Job
                    </Link>
                  </button>
                </div>
              </div>
              <Form form={form}>
                <div className="row pb-3 pb-sm-5">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <Selects
                      name="discipline_id"
                      placeText="Select Discipline"
                      labelText="Select Discipline"
                      req={false}
                      notFoundContent={DisciplineStatus === STATUSES.LOADING ? <Loading height={"30vh"} /> : null}
                      onSelect={(_, data) => {
                        onDataChange({ discipline: { id: data.value, name: data.children } });
                      }}
                      onClear={() => {
                        onDataChange({ discipline: null });
                      }}
                      onClick={getDisciplineData}
                      options={DisciplineData?.collection_list ?? []}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <Selects
                      name="state_id"
                      labelText="State"
                      placeText="State"
                      req={false}
                      notFoundContent={StateStatus === STATUSES.LOADING ? <Loading height={"30vh"} /> : null}
                      onSelect={(_, data) => {
                        onDataChange({ state: { id: data.value, name: data.children } });
                      }}
                      onClear={() => {
                        onDataChange({ state: null });
                      }}
                      onClick={getStateData}
                      options={StateData?.collection_list ?? []}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <Selects
                      name="hospital_id"
                      labelText="Hospital Type"
                      placeText="Hospital Type"
                      req={false}
                      disabled={selectedValues?.state != null ? false : true}
                      value={selectedValues?.hospitalType == null ? undefined : selectedValues.hospitalType}
                      notFoundContent={HospitalTypeStatus === STATUSES.LOADING ? <Loading height={"30vh"} /> : null}
                      onSelect={(_, data) => {
                        onDataChange({ hospitalType: { id: data.value, name: data.children } });
                      }}
                      onClear={() => {
                        onDataChange({ hospitalType: null });
                      }}
                      onClick={getHospitalTypeData}
                      options={HospitalTypeData ?? []}
                    />
                  </div>
                  <div className="col-xl-4 col-lg-5 col-md-4 col-sm-12">
                    <Selects
                      name="location_id"
                      labelText="Location"
                      placeText="Location"
                      req={false}

                      disabled={selectedValues?.state != null && selectedValues?.hospitalType != null ? false : true}
                      notFoundContent={LocationStatus === STATUSES.LOADING ? <Loading height={"30vh"} /> : null}
                      value={selectedValues?.location ?? undefined}
                      onSelect={(_, data) => {
                        onDataChange({ location: { id: data.value, name: data.children } });
                      }}
                      onClear={() => {
                        onDataChange({ location: null });
                      }}
                      onClick={getLocationData}
                      options={LocationData ?? []}
                    />

                  </div>
                  <div className="col-xl-5 col-lg-7 col-md-5 col-sm-12">
                    <RangePickers
                      name="Date_Posted"
                      req={false}
                      labelText="Date Posted"
                      onChange={(_, dateStrings) => {
                        if (dateStrings[0] == "") {
                          onDataChange({ dateRange: null });
                        } else {
                          onDataChange({ dateRange: `${dateStrings[0]} , ${dateStrings[1]}` });
                        }
                      }}
                      on
                    />
                  </div>
                  <Form.Item name="status" className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <Checkbox
                      value={selectedValues?.statusFilter}
                      onChange={(value) => {
                        onDataChange({ statusFilter: value.target.checked });
                      }}>Status</Checkbox>
                  </Form.Item>
                  <Form.Item name="featured" className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <Checkbox
                      value={selectedValues?.featured}
                      onChange={(value) => {
                        onDataChange({ featured: value.target.checked });
                      }}>Featured</Checkbox>
                  </Form.Item>
                  <Form.Item name="referral" className="col-xl-4 col-lg-4 col-md-4 col-sm-12 d-flex align-items-center">
                    <Checkbox
                      value={selectedValues?.referral}
                      onChange={(value) => {
                        onDataChange({ referral: value.target.checked });
                      }}>Referral</Checkbox>
                  </Form.Item>

                  <div className="col-sm-12 text-end mt-3 ">
                    <div className="d-flex justify-content-start">
                      <Button className="reject_btn" onClick={clearSelect}>Clear All Filters</Button>
                    </div>
                  </div>
                </div>

              </Form>
              {/* <JobListCard jobsList={jobsList?.data} /> */}
              <SystemConfigurationWrapped>

                <div className="row pb-3 justify-content-end">
                  <div className="col-sm-6 d-flex align-items-center">
                    <Search
                      onSearch={onSearch}
                      onChange={(value) => setSearch(value.value)}
                      prefix={<SearchOutlined width={"20px"} />}
                      allowClear
                      placeholder="Search"
                      value={search}
                      style={{
                        marginRight: "15px"
                      }}
                    />
                    <div className="col-sm-3 mt-3 mt-sm-0 text-end">
                      <Button className="reject_btn" loading={exportLoading} onClick={exportToExcel}>Export <DownloadImage /></Button>
                    </div>
                  </div>
                </div>

                {/* <div className="row"> */}
                {/* <div className="col-sm-12"> */}
                {/* <div className="tab_section"> */}
                {/* <div className="table-responsive"> */}
                <Table
                  dataSource={jobsList ?? []}
                  columns={columns}
                  loading={status === STATUSES.LOADING}
                  bordered={true}
                  pagination={false}
                  scroll={{ x: 1000 }}
                />
                {/* </div> */}
                {/* </div> */}
                {/* </div> */}
                {/* </div> */}
              </SystemConfigurationWrapped>

            </Col>
          </Row>
        </div>

        {
          <div className="featureBg__border p-2 d-flex justify-content-center w-100 pb-5">
            <Pagination
              current={jobsListData?.meta.page ?? 1}
              pageSize={jobsListData?.meta.per_page ?? 10}
              total={jobsListData?.meta.total}
              showSizeChanger={true}
              onChange={paginationOnChange}
              onShowSizeChange={paginationOnChange}
            />

          </div>
        }
      </JobRequestStyle >

    </>
  );
};

export default JobList;
