import React from "react";
import { DatePicker, Form } from "antd";
import styled from "styled-components";
const { RangePicker } = DatePicker;
const RangerPickerWrapper = styled.div`
  .ant-picker {
    background: rgb(247, 247, 247);
    height: 38px;
    border: 0.5px solid rgb(184, 184, 184);
    border-radius: 5px;
  }
  .ant-row {
    display: inline;
  }
  .ant-form-item-label > label {
    font-size: 16px;
  }
`;
const RangePickers = ({
  labelText,
  name,
  sizes,
  req,
  rangePickerType,
  placeText,
  dateFormat,
  marginBottom,
  onChange,
}) => {
  return (
    <RangerPickerWrapper>
      <Form.Item
        name={name ?? "name"}
        label={labelText ?? ""}
        className={marginBottom ? `${marginBottom} w-100` : "w-100 mb-4"}
        rules={[
          {
            required: req === false ? false : true,
            message: "This field is required!!",
          },
        ]}
      >
        <RangePicker
          className='w-100'
          size={sizes ?? "middle"}
          picker={rangePickerType ?? "date"}
          placeholder={placeText ?? ["Start date", "End date"]}
          format={dateFormat ?? "MM-DD-YYYY"}
          onChange={onChange}
        />
      </Form.Item>
    </RangerPickerWrapper>
  );
};

export default RangePickers;
