import { Modal } from "antd";
import React from "react";

const HospitalTypeModal = ({
    isModalOpen,
    setIsModalOpen,
    modalData,
}) => {

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    
    return (
        <>
            <Modal
                className="modal-styles"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                // width={600}
                maskClosable={false}
                centered={true}
                title={false}
            >
                <div className="row">
                    <div className="col-md-12">
                        <h5 className="table-heading mb-0 ">Hospital Type Details</h5>
                    </div>
                </div>
                <div className="modal-content">
                    <div className="section-style">
                        <div className="row space-between">
                            <div className="col-md-12 px-2">
                                <h6 className="heading-title">
                                   <strong>State : </strong>{ modalData?.state?.name ?? " "}
                                </h6>
                            </div>
                        </div>
                        <div className="row space-between">
                            <div className="col-md-12 px-2">
                                <h6 className="heading-title">
                                    <strong>Parent Hospital Type : </strong>{ modalData?.parent_hospital_type?.name ?? "   -----"}
                                </h6>
                            </div>
                        </div>
                        <div className="row space-between w-100">
                            <div className="col-md-12 col-xs-12">
                                <div className="d-flex align-items-center justify-content-start pl-3">
                                    <div>
                                        <h6 className="heading-title">
                                           <strong>Hospital Type : </strong>{modalData?.name ?? " "}
                                        </h6>

                                    </div>
                                </div>
                                <hr style={{ marginTop: "20px" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default HospitalTypeModal;