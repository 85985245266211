import React from "react";
import styled from "styled-components";
import { SearchOutlined } from "@ant-design/icons";
import Search from "antd/lib/input/Search";
const SearchInputWrapped = styled.div`
  .ant-input-affix-wrapper {
    background: rgba(55, 35, 84, 0.13);
    border: 0.5px solid rgba(164, 164, 164, 0.1);
    box-shadow: 0px 2px 3px 1px rgba(113, 113, 113, 0.04);
    border-radius: 8px !important;
    padding: 4px 11px;
    color: rgba(55, 35, 84, 0.25);
    font-weight: 400;
    font-size: 20px;
    border-right-width: 1px !important;
    z-index: 1;
    border: 0.6px solid rgba(0, 0, 0, 0.2);
    background: #f5f5f5 !important;
    .ant-input {
      background: #f5f5f5 !important;
      font-weight: 400;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.85);
      width: 300px;
      @media (max-width: 768px) {
        width: auto;
      }
    }
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
`;
const SearchInput = ({ onSearch, setSearch, search }) => {
  console.log("searchsearch", search);
  return (
    <SearchInputWrapped>
      <Search
        onSearch={onSearch}
        onChange={(value) => setSearch(value.value)}
        prefix={<SearchOutlined width={"20px"} />}
        autoFocus={true}
        placeholder='Search'
        value={search}
        style={{
          width: "300",
        }}
      />
    </SearchInputWrapped>
  );
};

export default SearchInput;
