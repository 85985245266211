import { Form } from "antd";
import React, { useEffect, useState, } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getJobListPageContentRequest,
  postJobListPageContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";

const JobListPage = () => {

  const dispatch = useDispatch();
  const { jobListPageData, jobListPageStatus, jobListPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();

  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();

  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getJobListPageContentRequest(CONTENT_PAGES.JOB_LIST));
  }, [dispatch]);

  useEffect(() => {
    if (jobListPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]": jobListPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]": jobListPageData?.contentPage?.detail?.bannerSection?.description,
        "detail[bannerSection][buttonText]": jobListPageData?.contentPage?.detail?.bannerSection?.buttonText,
        //Seo Values Set
        meta_title: jobListPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: jobListPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: jobListPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: jobListPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: jobListPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: jobListPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: jobListPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: jobListPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: jobListPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: jobListPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: jobListPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: jobListPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: jobListPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: jobListPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: jobListPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: jobListPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: jobListPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: jobListPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: jobListPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(jobListPageData?.contentPage?.contentImages?.bannerImage ?? BannerImage);
      setTwitterImageUrl(jobListPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(jobListPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);

    }
  }, [jobListPageData]);

  const onUpdate = (values) => {

    const data = {
      ...values,
      name: 'Job List',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[bannerImage]": bannerSectionImageFile ?? "",
      _method: 'PUT'
    }
    dispatch(postJobListPageContentRequest({ data, id: jobListPageData?.contentPage?.id }))
  };

  return (
    <>
      {jobListPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : jobListPageStatus === STATUSES.ERROR ? (
        <div>{jobListPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Job List</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Button Text"
                      name="detail[bannerSection][buttonText]"
                      placeText="Banner Section Button Text"
                    />
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default JobListPage;
