import { Form } from "antd";
import React, { useEffect, useState, } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getRegisterContentRequest,
  postRegisterContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";

const RegisterPage = () => {

  const dispatch = useDispatch();
  const { registerPageData, registerPageStatus, registerPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();

  const [firstSliderImageUrl, setFirstSliderImageUrl] = useState();
  const [firstSliderImageFile, setFirstSliderImageFile] = useState();
  const [secondSliderImageUrl, setSecondSliderImageUrl] = useState();
  const [secondSliderImageFile, setSecondSliderImageFile] = useState();
  const [thirdSliderImageUrl, setThirdSliderImageUrl] = useState();
  const [thirdSliderImageFile, setThirdSliderImageFile] = useState();
  const [forthSliderImageUrl, setForthSliderImageUrl] = useState();
  const [forthSliderImageFile, setForthSliderImageFile] = useState();

  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  const contentPage = registerPageData?.contentPage?.detail;
  useEffect(() => {
    dispatch(getRegisterContentRequest(CONTENT_PAGES.REGISTER_PAGE));
  }, [dispatch]);
  useEffect(() => {
    if (registerPageData) {
      form.setFieldsValue({
        "detail[registerSection][title]": contentPage?.registerSection?.title,
        "detail[registerSection][description]": contentPage?.registerSection?.description,
        "detail[registerSection][registerButtonText]": contentPage?.registerSection?.registerButtonText,
        "detail[registerSection][haveAccountText]": contentPage?.registerSection?.haveAccountText,
        "detail[registerSection][firstSliderText]": contentPage?.registerSection?.firstSliderText,
        "detail[registerSection][firstSliderDescription]": contentPage?.registerSection?.firstSliderDescription,
        "detail[registerSection][secondSliderText]": contentPage?.registerSection?.secondSliderText,
        "detail[registerSection][secondSliderDescription]": contentPage?.registerSection?.secondSliderDescription,
        "detail[registerSection][thirdSliderText]": contentPage?.registerSection?.thirdSliderText,
        "detail[registerSection][thirdSliderDescription]": contentPage?.registerSection?.thirdSliderDescription,
        "detail[registerSection][forthSliderText]": contentPage?.registerSection?.forthSliderText,
        "detail[registerSection][forthSliderDescription]": contentPage?.registerSection?.forthSliderDescription,

        //Seo Values Set
        meta_title: registerPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: registerPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: registerPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: registerPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: registerPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: registerPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: registerPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: registerPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: registerPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: registerPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: registerPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: registerPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: registerPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: registerPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: registerPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: registerPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: registerPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: registerPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: registerPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setFirstSliderImageUrl(registerPageData?.contentPage?.contentImages?.sliderFirstImage ?? BannerImage);
      setSecondSliderImageUrl(registerPageData?.contentPage?.contentImages?.sliderSecondImage ?? BannerImage);
      setThirdSliderImageUrl(registerPageData?.contentPage?.contentImages?.sliderThirdImage ?? BannerImage);
      setForthSliderImageUrl(registerPageData?.contentPage?.contentImages?.sliderForthImage ?? BannerImage);
      setTwitterImageUrl(registerPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(registerPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);

    }
  }, [registerPageData]);

  const onUpdate = (values) => {

    const data = {
      ...values,
      name: 'Register Page',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[sliderFirstImage]": firstSliderImageFile ?? "",
      "contentImages[sliderSecondImage]": secondSliderImageFile ?? "",
      "contentImages[sliderThirdImage]": thirdSliderImageFile ?? "",
      "contentImages[sliderForthImage]": forthSliderImageFile ?? "",
      _method: 'PUT'
    }
    dispatch(postRegisterContentRequest({ data, id: registerPageData?.contentPage?.id }))
  };

  return (
    <>
      {registerPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : registerPageStatus === STATUSES.ERROR ? (
        <div>{registerPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >Register Page</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Register Section Title"
                      name="detail[registerSection][title]"
                      placeText="Register Section Title"
                    />
                    <TextAreas
                      labelText="Register Section Description"
                      name="detail[registerSection][description]"
                      placeText="Register Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Register Section Register Button Text"
                      name="detail[registerSection][registerButtonText]"
                      placeText="Register Section Register Button Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Register Section Have Account Text"
                      name="detail[registerSection][haveAccountText]"
                      placeText="Register Section Have Account Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="First Slider Text"
                      name="detail[registerSection][firstSliderText]"
                      placeText="First Slider Text"
                    />
                    <TextAreas
                      labelText="First Slider Description"
                      name="detail[registerSection][firstSliderDescription]"
                      placeText="First Slider Description"
                    />
                    <BannerImageCard
                      title="First Slider Image"
                      imageUrl={firstSliderImageUrl}
                      isBlogImage={true}
                      setImageFile={setFirstSliderImageFile}
                      setImageUrl={setFirstSliderImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Second Slider Text"
                      name="detail[registerSection][secondSliderText]"
                      placeText="Second Slider Text"
                    />
                    <TextAreas
                      labelText="Second Slider Description"
                      name="detail[registerSection][secondSliderDescription]"
                      placeText="Second Slider Description"
                    />
                    <BannerImageCard
                      title="Second Slider Image"
                      imageUrl={secondSliderImageUrl}
                      isBlogImage={true}
                      setImageFile={setSecondSliderImageFile}
                      setImageUrl={setSecondSliderImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Third Slider Text"
                      name="detail[registerSection][thirdSliderText]"
                      placeText="Third Slider Text"
                    />
                    <TextAreas
                      labelText="Third Slider Description"
                      name="detail[registerSection][thirdSliderDescription]"
                      placeText="Third Slider Description"
                    />
                    <BannerImageCard
                      title="Third Slider Image"
                      imageUrl={thirdSliderImageUrl}
                      isBlogImage={true}
                      setImageFile={setThirdSliderImageFile}
                      setImageUrl={setThirdSliderImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Forth Slider Text"
                      name="detail[registerSection][forthSliderText]"
                      placeText="Forth Slider Text"
                    />
                    <TextAreas
                      labelText="Forth Slider Description"
                      name="detail[registerSection][forthSliderDescription]"
                      placeText="Forth Slider Description"
                    />
                    <BannerImageCard
                      title="Forth Slider Image"
                      imageUrl={forthSliderImageUrl}
                      isBlogImage={true}
                      setImageFile={setForthSliderImageFile}
                      setImageUrl={setForthSliderImageUrl}
                      imageText="Change Image"
                    />
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default RegisterPage;
