import React from "react";
import { Spin } from "antd";
import styled from "styled-components";
const LoadingWrapped = styled.div`
  .ant-spin-dot-item {
    background-color: #33a161;
  }
`;
const Loading = ({ height }) => {
  return (
    <LoadingWrapped>
      <div
        className="d-flex justify-content-center align-items-center secondary_color"
        style={{ height: height ?? "100vh", width: "100%" }}
      >
        <Spin className="secondary_color" tip="Loading..."></Spin>
      </div>
    </LoadingWrapped>
  );
};
export default Loading;
