import { DeleteOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Pagination,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import Search from "antd/lib/input/Search";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import RangePickers from "../../../components/DatePicker/RangePickers";
import { errorNotification } from "../../../components/notifications/alertNotification";
import { STATUSES } from "../../../config/constants/defaultValues";
import {
  deleteConfigurationalHospitalTypeRequest,
  getConfigurationalHospitalTypeListExportDataRequest,
  getConfigurationalHospitalTypeListRequest,
} from "../../../redux/systemConfigurationSlice";
import { ReactComponent as DownloadImage } from "../../../assets/images/download.svg";
import { JobRequestStyle } from "../../style";
import { SystemConfigurationWrapped } from "../style";
import HospitalTypeModal from "./HospitalTypeDetailModal";
import HospitalTypeEditModal from "./HospitalTypeEditModal";
import HospitalTypeCreateModal from "./HospitalTypeCreateModal";

const HospitalTypeConfiguration = () => {
  const dispatch = useDispatch();
  const { hospitalTypeStatus, hospitalTypeData, hospitalTypeError } =
    useSelector((hospitalType) => hospitalType.systemConfiguration);
  const [search, setSearch] = useState();
  const [exportLoading, setExportLoading] = useState(false);
  const [modalData, setModelData] = useState();
  const [editModalData, setEditModelData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState("");

  useEffect(() => {
    if (hospitalTypeStatus !== STATUSES.LOADED) {
      dispatch(getConfigurationalHospitalTypeListRequest({}));
    }
  }, [dispatch]);

  const paginationOnChange = (data, pageSize) => {
    dispatch(
      getConfigurationalHospitalTypeListRequest({
        generalSearch: search,
        page: data,
        pageSize: pageSize,
        dateRange: dateRange,
      })
    );
  };

  const onDateChange = ({ dateRange }) => {
    setDateRange(dateRange);
    dispatch(
      getConfigurationalHospitalTypeListRequest({
        generalSearch: search,
        dateRange: dateRange,
      })
    );
  };

  const onSearch = (generalSearch) => {
    setSearch(generalSearch);
    dispatch(
      getConfigurationalHospitalTypeListRequest({
        generalSearch,
        dateRange: dateRange,
      })
    );
  };

  const exportToExcel = async () => {
    setExportLoading(true);
    try {
      const data = await dispatch(
        getConfigurationalHospitalTypeListExportDataRequest({
          generalSearch: search,
          dateRange: dateRange,
        })
      );

      let filtered = [...data.payload];

      const worksheet = XLSX.utils.json_to_sheet(
        filtered.map((record) => ({
          State: record?.state?.name,
          "Parent Hospital Type": record?.parent_hospital_type?.name ?? "----",
          "Hospital Type": record.name,
          "Updated Time": record.updated_at,
        }))
      );

      const currentDateTimeString = moment(new Date()).format(
        "MM-DD-YYYY-hh:mm-A"
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(
        workbook,
        `imperial-hospital-types-DataSheet-${currentDateTimeString}.xlsx`
      );
    } catch (error) {
      errorNotification("Error while fetching or processing data:", error);
    } finally {
      setExportLoading(false); // Ensure that loading indicator is turned off, even in case of an error
    }
  };

  const showModal = (record) => {
    setModelData(record);
    setIsModalOpen(true);
  };

  const showEditModal = (record) => {
    setEditModelData(record);
    setIsEditModalOpen(true);
  };
  const showCreateModal = () => {
    setIsCreateModalOpen(true);
  };
  const deleteHospitalType = (id) => {
    dispatch(deleteConfigurationalHospitalTypeRequest(id));
    dispatch(getConfigurationalHospitalTypeListRequest({}));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: "10%",
      // ...getColumnSearchProps('id'),
    },
    {
      title: "State",
      dataIndex: "state",
      width: "15%",
      ellipsis: true,

      render: (text) => <span strong>{text.name}</span>,
    },
    {
      title: "Parent Hospital Type",
      dataIndex: "parent_hospital_type",
      width: "20%",
      ellipsis: true,

      render: (text) => <span strong>{ text?.name ?? '----' }</span>,
    },
    {
      title: "Hospital Type",
      dataIndex: "name",
      width: "25%",
      ellipsis: true,

      // render: (text) => <Text strong>{text.name}</Text>,
    },
    {
      title: "Updated Date",
      dataIndex: "updated_at",
      ellipsis: true,
      // render: (text) => moment(text).format('MM-DD-YYYY'),
      sorter: (a, b) => {
        // Convert the date strings to Date objects for comparison
        const dateA = new Date(a.updated_at);
        const dateB = new Date(b.updated_at);

        return dateA - dateB; // To sort in ascending order
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: "15%",
      render: (text, record) => (
        <Space>
          <span
            style={{
              color: "#372354",
              cursor: "pointer",
            }}
            className="job_view"
            onClick={() => showModal(record)}
          >
            <EyeOutlined />
          </span>
          <span
            style={{
              color: "#372354",
              cursor: "pointer",
            }}
            className="job_view"
            onClick={() => showEditModal(record)}
          >
            <i class="bi bi-pencil" />
          </span>
          <span
            style={{
              color: "#962020",
              cursor: "pointer",
            }}
            className="job_view"
          >
            <Popconfirm
              Popconfirm
              title="Are you sure you want to delete this?"
              okText="Delete"
              placement="top"
              onConfirm={() => deleteHospitalType(record.id)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </span>
        </Space>
      ),
    },
  ];

  return (
    <JobRequestStyle>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <div className="row  pb-3 pb-sm-5">
              <div className="col-sm-9">
                <div className="heading">Hospital Type List</div>
              </div>
              <div className="col-sm-3">
                <button
                  className="approved_btn_w px-2 border-0 mt-2 mt-sm-0"
                  onClick={() => showCreateModal()}
                >
                  Create New
                </button>
              </div>
            </div>
            <div className="col-xl-5 col-lg-7 col-md-5 col-sm-12">
              <RangePickers
                name="Date_Posted"
                req={false}
                labelText="Date Range"
                onChange={(_, dateStrings) => {
                  if (dateStrings[0] == "") {
                    onDateChange({ dateRange: null });
                  } else {
                    onDateChange({
                      dateRange: `${dateStrings[0]} , ${dateStrings[1]}`,
                    });
                  }
                }}
                on
              />
            </div>

            {/* <UserListCard jobsList={hospitalTypeData?.data} /> */}
            <SystemConfigurationWrapped>
              <div className="row pb-3 ">
                <div className="col-sm-6 p-sm-0">
                  <Search
                    onSearch={onSearch}
                    onChange={(value) => setSearch(value.value)}
                    prefix={<SearchOutlined width={"20px"} />}
                    allowClear
                    placeholder="Search"
                    value={search}
                    style={{
                      marginRight: "15px",
                    }}
                  />
                </div>
                <div className="col-sm-6 mt-3 mt-sm-0 text-end">
                  <Button
                    className="reject_btn"
                    loading={exportLoading}
                    onClick={exportToExcel}
                  >
                    Export <DownloadImage />
                  </Button>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 p-sm-0">
                  <Table
                    loading={hospitalTypeStatus === STATUSES.LOADING}
                    dataSource={hospitalTypeData?.data ?? []}
                    columns={columns}
                    bordered={true}
                    indentSize={33}
                    pagination={false} // Disable pagination
                    scroll={{ x: 600 }}
                  />
                </div>
              </div>
            </SystemConfigurationWrapped>
          </Col>
        </Row>
      </div>

      {
        <div className="featureBg__border p-2 d-flex justify-content-center w-100 pb-5">
          <Pagination
            current={hospitalTypeData?.meta.page ?? 1}
            pageSize={hospitalTypeData?.meta.per_page ?? 10}
            total={hospitalTypeData?.meta.total}
            showSizeChanger={true}
            onChange={paginationOnChange}
            onShowSizeChange={paginationOnChange}
          />
        </div>
      }
      <HospitalTypeModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalData={modalData}
      />
      <HospitalTypeEditModal
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
        modalData={editModalData}
      />
      <HospitalTypeCreateModal
        isModalOpen={isCreateModalOpen}
        setIsModalOpen={setIsCreateModalOpen}
      />
    </JobRequestStyle>
  );
};

export default HospitalTypeConfiguration;
