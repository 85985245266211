import React, { useState } from "react";
import { SystemConfigurationSideBarWrapped, SystemConfigurationWrapped } from "./style";
import { Menu } from "antd";
import DisciplineConfiguration from "./Discipline/disciplineConfiguration";
import { GET_DISCIPLINES_LIST_API, GET_STATES_LIST_API, GET_HOSPITAL_TYPES_LIST_API, GET_LOCATIONS_LIST_API, GET_TESTIMONIALS_LIST_API } from "../../config/constants/endPoints";
import StateConfiguration from "./State/stateConfiguration";
import HospitalTypeConfiguration from "./HospitalType/hospitalTypeConfiguration";
import LocationConfiguration from "./Location/locationConfiguration";
import TestimonialConfiguration from "./Testimonial/TestimonialConfiguration";

const SystemConfiguration = () => {
  // const dispatch = useDispatch();
  const [dataList, setDataList] = useState([
    {
      "id": 1,
      "apiPath": GET_DISCIPLINES_LIST_API,
      "model_name": "Discipline",
    },
    {
      "id": 2,
      "apiPath": GET_STATES_LIST_API,
      "model_name": "State",
    },
    {
      "id": 3,
      "apiPath": GET_HOSPITAL_TYPES_LIST_API,
      "model_name": "Hospital Type",
    },
    {
      "id": 4,
      "apiPath": GET_LOCATIONS_LIST_API,
      "model_name": "Location",
    },
    {
      "id": 5,
      "apiPath": GET_TESTIMONIALS_LIST_API,
      "model_name": "Testimonial",
    },
  ]);

  // const [systemConfigurationCustomizeList, setSystemConfigurationCustomizeList] = useState([]);
  const [selectedTile, setSelectedTile] = useState(1);

  // const { systemConfigurationListData, systemConfigurationListStatus, systemConfigurationListError } = useSelector(
  //   (state) => state.systemConfiguration
  // );

  // useEffect(() => {
  //   dispatch(getSystemConfigurationListRequest({}));
  // }, [dispatch]);

  // useEffect(() => {
  //   if (systemConfigurationListData) {
  //     dispatch(getSystemConfigurationDetailRequest({ modelData: systemConfigurationListData[0] }));
  //     setSystemConfigurationCustomizeList(systemConfigurationListData)
  //     setDataList(systemConfigurationListData)
  //   }
  // }, [dispatch, systemConfigurationCustomizeList]);

  const onItemClick = (data) => {
    // dispatch(getSystemConfigurationDetailRequest({ modelData: data }));
    setSelectedTile(data.id)
  }
  const Page = ({ currentPage }) => {
    return (
      <div>
        {currentPage === 1 && <DisciplineConfiguration />}
        {currentPage === 2 && <StateConfiguration />}
        {currentPage === 3 && <HospitalTypeConfiguration />}
        {currentPage === 4 && <LocationConfiguration />}
        {currentPage === 5 && <TestimonialConfiguration />}
      </div>
    );
  };

  // const onSearch = (inputData) => {
  //   const filtered = systemConfigurationCustomizeList.filter((systemData) => {
  //     if (inputData.target.value === '') {
  //       return systemConfigurationCustomizeList;
  //     }
  //     else {
  //       return systemData.model_name.toLowerCase().includes(inputData.target.value.toLowerCase())
  //     }
  //   })
  //   setDataList(filtered);
  // }

  // console.log('systemConfigurationListData', systemConfigurationListData)

  return (
    <>
      {/* {systemConfigurationListStatus === STATUSES.LOADING ? (
        <Loading />
      ) : systemConfigurationListStatus === STATUSES.ERROR ? (
        <div>{systemConfigurationListError}</div>
      ) : ( */}
      <SystemConfigurationWrapped>
        <div className="layout-content">
          <div className="row">
            <div className="col-lg-3 col-12">
              <SystemConfigurationSideBarWrapped>
                <div className="side-tabs">
                  {/* <div className="m-0"> 
                     <Form>
                        <BaseInput
                          className="search_style"
                          name="generalSearch"
                          placeText="Search"
                          inputIcon={<SearchOutlined />}
                          onChange={onSearch}
                          req={false}
                        />
                      </Form> 
                  </div> */}
                  <div>
                    <Menu theme="light" mode="inline" defaultSelectedKeys={["0"]}>
                      {dataList.map((systemConfigurationData, index) => (
                        <Menu.Item key={index}
                          onClick={() => onItemClick(systemConfigurationData)}
                        >
                          <span className="label">{systemConfigurationData.model_name}</span>
                        </Menu.Item>
                      ))}
                    </Menu>
                  </div>
                </div>
              </SystemConfigurationSideBarWrapped>
            </div>
            <div className="col-lg-9 col-12">
              <div className="tab-content" id="v-pills-tabContent">
                <Page currentPage={selectedTile} />
              </div>
            </div>
          </div>
        </div>
      </SystemConfigurationWrapped>
      {/* )} */}
    </>
  );
};

export default SystemConfiguration;
