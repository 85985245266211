import { Form } from "antd";
import React, { useEffect, useState } from "react";
import BaseInput from "../../components/form/BaseInput";
import { ContentWrapped } from "./style";
import SaveButton from "../../components/button/saveButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getCookiePolicyPageContentRequest,
  postCookiePolicyPageContentRequest,
} from "../../redux/contentManagementSlice";
import Loading from "../../components/shared/GeneralComponents";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";

const CookiePolicyPage = () => {
  const dispatch = useDispatch();
  const { cookiePolicyPageData, cookiePolicyPageStatus, cookiePolicyPageError } = useSelector(
    (state) => state.contentManagement
  );
  const [form] = Form.useForm();

  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getCookiePolicyPageContentRequest(CONTENT_PAGES.COOKIE_POLICY_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (cookiePolicyPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]": cookiePolicyPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]":cookiePolicyPageData?.contentPage?.detail?.bannerSection?.description,
        "detail[innerSection][firstHeading]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.firstHeading,
        "detail[innerSection][firstDescription]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.firstDescription,
        "detail[innerSection][secondHeading]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.secondHeading,
        "detail[innerSection][secondDescription]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.secondDescription,
        "detail[innerSection][thirdHeading]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.thirdHeading,
        "detail[innerSection][thirdDescription]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.thirdDescription,
        "detail[innerSection][forthHeading]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.forthHeading,
        "detail[innerSection][forthDescription]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.forthDescription,
        "detail[innerSection][fifthHeading]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.fifthHeading,
        "detail[innerSection][fifthDescription]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.fifthDescription,
        "detail[innerSection][sixHeading]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.sixHeading,
        "detail[innerSection][sixDescription]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.sixDescription,
        "detail[innerSection][sevenHeading]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.sevenHeading,
        "detail[innerSection][sevenDescription]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.sevenDescription,
        "detail[innerSection][eightHeading]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.eightHeading,
        "detail[innerSection][eightDescription]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.eightDescription,
        "detail[innerSection][nineHeading]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.nineHeading,
        "detail[innerSection][nineDescription]": cookiePolicyPageData?.contentPage?.detail?.innerSection?.nineDescription,
      //Seo Values Set
      meta_title: cookiePolicyPageData?.contentPage?.seo_keywords?.meta_title ?? "",
      meta_description: cookiePolicyPageData?.contentPage?.seo_keywords?.meta_description ?? "",
      is_canonical: cookiePolicyPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
      robots_meta: cookiePolicyPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
      meta_author: cookiePolicyPageData?.contentPage?.seo_keywords?.meta_author ?? "",
      og_type: cookiePolicyPageData?.contentPage?.seo_keywords?.og_type ?? "",
      og_title: cookiePolicyPageData?.contentPage?.seo_keywords?.og_title ?? "",
      og_description: cookiePolicyPageData?.contentPage?.seo_keywords?.og_description ?? "",
      og_image_width: cookiePolicyPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
      og_image_height: cookiePolicyPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
      og_url: cookiePolicyPageData?.contentPage?.seo_keywords?.og_url ?? "",
      og_site_name: cookiePolicyPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
      og_locale: cookiePolicyPageData?.contentPage?.seo_keywords?.og_locale ?? "",
      og_locale_alternate: cookiePolicyPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
      twitter_card: cookiePolicyPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
      twitter_site: cookiePolicyPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
      twitter_title: cookiePolicyPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
      twitter_description: cookiePolicyPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
      twitter_url: cookiePolicyPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
    });
    setTwitterImageUrl(cookiePolicyPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
    setOGImageUrl(cookiePolicyPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
    }
  }, [cookiePolicyPageData]);

  const onUpdate = (values) => {
    const data={
      ...values,
      name:'Cookie Policy',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      _method: 'PUT'
    }
    dispatch(postCookiePolicyPageContentRequest({data,id:cookiePolicyPageData?.contentPage?.id})) 
  };
  return (
    <>
    {cookiePolicyPageStatus === STATUSES.LOADING ? (
      <Loading />
    ) : cookiePolicyPageError === STATUSES.ERROR ? (
      <div>{cookiePolicyPageError}</div>
    ) : (
      <ContentWrapped>
        <div className="row">
          <div className="col-sm-12">
            <Form
              layout="vertical"
              className="pt-3"
              form={form}
              onFinish={onUpdate}
            >
              <div className="row">
                <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                  <h3 className="font-bold" >Cookie Policy</h3>
                  <SaveButton title="Update" />
                </div>
              </div>
              <div className="row">
              <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                  {/* Banner Section */}
                  {/* <HeadingTitle title="Banner Section" /> */}
                  <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Description"
                      name="detail[bannerSection][description]"
                      placeText="Banner Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inner Section First Heading"
                      name="detail[innerSection][firstHeading]"
                      placeText="Inner Section First Heading"
                    />
                    <TextAreas
                      labelText="Inner Section First Description"
                      name="detail[innerSection][firstDescription]"
                      placeText="Inner Section First Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inner Section Second Heading"
                      name="detail[innerSection][secondHeading]"
                      placeText="Inner Section Second Heading"
                    />
                    <TextAreas
                      labelText="Inner Section Second Description"
                      name="detail[innerSection][secondDescription]"
                      placeText="Inner Section Second Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inner Section Third Heading"
                      name="detail[innerSection][thirdHeading]"
                      placeText="Inner Section Third Heading"
                    />
                    <TextAreas
                      labelText="Inner Section Third Description"
                      name="detail[innerSection][thirdDescription]"
                      placeText="Inner Section Third Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inner Section Forth Heading"
                      name="detail[innerSection][forthHeading]"
                      placeText="Inner Section Forth Heading"
                    />
                    <TextAreas
                      labelText="Inner Section Forth Description"
                      name="detail[innerSection][forthDescription]"
                      placeText="Inner Section Forth Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inner Section Fifth Heading"
                      name="detail[innerSection][fifthHeading]"
                      placeText="Inner Section Fifth Heading"
                    />
                    <TextAreas
                      labelText="Inner Section Fifth Description"
                      name="detail[innerSection][fifthDescription]"
                      placeText="Inner Section Fifth Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inner Section Six Heading"
                      name="detail[innerSection][sixHeading]"
                      placeText="Inner Section Six Heading"
                    />
                    <TextAreas
                      labelText="Inner Section Six Description"
                      name="detail[innerSection][sixDescription]"
                      placeText="Inner Section Six Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inner Section Seven Heading"
                      name="detail[innerSection][sevenHeading]"
                      placeText="Inner Section Seven Heading"
                    />
                    <TextAreas
                      labelText="Inner Section Seven Description"
                      name="detail[innerSection][sevenDescription]"
                      placeText="Inner Section Seven Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inner Section Eight Heading"
                      name="detail[innerSection][eightHeading]"
                      placeText="Inner Section Eight Heading"
                    />
                    <TextAreas
                      labelText="Inner Section Eight Description"
                      name="detail[innerSection][eightDescription]"
                      placeText="Inner Section Eight Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Inner Section Nine Heading"
                      name="detail[innerSection][nineHeading]"
                      placeText="Inner Section Nine Heading"
                    />
                    <TextAreas
                      labelText="Inner Section Nine Description"
                      name="detail[innerSection][nineDescription]"
                      placeText="Inner Section Nine Description"
                    />
                </div>
                <SeoSection ogImageUrl={ogImageUrl}
                  setOGImageFile={setOGImageFile}
                  setOGImageUrl={setOGImageUrl}
                  twitterImageUrl={twitterImageUrl}
                  setTwitterImageFile={setTwitterImageFile}
                  setTwitterImageUrl={setTwitterImageUrl} />
              </div>

            </Form>
          </div>
        </div>
      </ContentWrapped>
    )}
  </>
);
};

export default CookiePolicyPage;
