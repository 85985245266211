import { notification } from "antd";

export const successNotification = (data) => {
  notification.open({
    message: data,
    duration: 3,
    style: { backgroundColor: "#33A161" },
  });
};
export const errorNotification = (data) => {
  notification.open({
    message: data,
    duration: 3,
    style: { backgroundColor: "#D32F2F" },
  });
};
export const warningNotification = (data) => {
  notification.open({
    message: data,
    duration: 3,
    style: { backgroundColor: "#0288D1" },
  });
};
