import callAPI from "./axios.service";

const apiService = async (endPoint, method, data, Token, customUrl) => {
     console.log("in Api Service",endPoint, method, data)
  // alert("call api")
  // const encData=await encryption(data)
  // console.log(`encData`, encData)
  const response = await callAPI({
    url: endPoint,
    method,
    data: data,
    withoutToken: Token,
    customUrl: customUrl,
  });


  if (response.status === 401 || response.status === 500) {
    return response;
  }

  const newRes = { ...response };

  return newRes;
};

export { apiService };
