import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { errorNotification } from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import { JOBS_APPLIED_LIST_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";

export const getJobAppliedListRequest = createAsyncThunk(
    "jobAppliedListRequest/getJobAppliedListRequest",
    async ({ generalSearch, page, pageSize, discipline, state, hospitalType, location, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                JOBS_APPLIED_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": page ?? 1, "per_page": pageSize ?? 30, "export": "0" },
                    "query": { "status": "1", "generalSearch": generalSearch, "discipline": discipline ?? "", "state": state ?? "", "hospitalType": hospitalType ?? "", "location": location ?? "", "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

export const getJobAppliedExportListRequest = createAsyncThunk(
    "jobAppliedListRequest/getJobAppliedExportListRequest",
    async ({ generalSearch, discipline, state, hospitalType, location, dateRange }, { rejectWithValue }) => {
        try {
            const response = await apiService(
                JOBS_APPLIED_LIST_API,
                REQUEST_METHOD.POST,
                {
                    "pagination": { "page": 1, "per_page": 30, "export": "1" },
                    "query": { "status": "1", "generalSearch": generalSearch, "discipline": discipline ?? "", "state": state ?? "", "hospitalType": hospitalType ?? "", "location": location ?? "", "dateRange": dateRange ?? "" }
                },
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

const jobAppliedRequestSlice = createSlice({
    name: "jobAppliedList",
    initialState: {
        jobAppliedData: null,
        status: STATUSES.IDLE,
        error: null,
    },
    extraReducers: {
        [getJobAppliedListRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;
        },
        [getJobAppliedListRequest.fulfilled]: (state, action) => {

            state.status = STATUSES.IDLE;
            state.jobAppliedData = action.payload;
        },
        [getJobAppliedListRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },

    },
});
export default jobAppliedRequestSlice.reducer;

