import { Form, Modal } from "antd";
import React, { useState } from "react";
import SaveButton from "../../../components/button/saveButton";
import BaseInput from "../../../components/form/BaseInput";
import {
  createConfigurationalLocationRequest,
  getConfigurationalLocationListRequest,
} from "../../../redux/systemConfigurationSlice";
import { useDispatch, useSelector } from "react-redux";
import { BannerImage, STATUSES } from "../../../config/constants/defaultValues";
import Selects from "../../../components/select/Selects";
import Loading from "../../../components/shared/GeneralComponents";
import {
  getHospitalTypeListRequest,
  getStateListRequest,
} from "../../../redux/appDataSlice";
import { errorNotification } from "../../../components/notifications/alertNotification";
import BannerImageCard from "../../../components/shared/bannerImageCard";

const LocationCreateModal = ({ isModalOpen, setIsModalOpen }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  
  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState(BannerImage);
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();

  const [thumbnailImageUrl, setThumbnailImageUrl] = useState(BannerImage);
  const [thumbnailImageFile, setThumbnailImageFile] = useState();

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [selectedValues, setSelectedValues] = useState(null);
  const { StateData, StateStatus, HospitalTypeData, HospitalTypeStatus } =
    useSelector((state) => state.appData);

  const getStateData = () => {
    if (StateStatus === STATUSES.IDLE) {
      dispatch(getStateListRequest());
    }
  };

  const getHospitalTypeData = () => {
    if (selectedValues?.state?.id) {
      dispatch(getHospitalTypeListRequest({ state: selectedValues.state.id }));
    } else {
      errorNotification("Please Select State First");
    }
  };
  const onDataChange = ({ state, hospitalType }) => {
    // Copy the existing selectedValues into a new object
    const newSelectedValues = { ...selectedValues };

    if (state !== undefined) {
      newSelectedValues.state = state;
      newSelectedValues.hospitalType = null;
      form.resetFields(["hospital_type_id"]);
    }

    if (hospitalType !== undefined) {
      newSelectedValues.hospitalType = hospitalType;
    }
    setSelectedValues(newSelectedValues);
  };
  //Save Button
  const onSave = async (values) => {
    setIsLoading(true);

    await dispatch(
      createConfigurationalLocationRequest({
        values: {
          ...values,
          banner_image: bannerSectionImageFile ?? "",
          thumbnail_image: thumbnailImageFile ?? "",
          status: "1",
        },
      })
    ).then((data) => {
      if (data.payload.status === 200) {
        dispatch(getConfigurationalLocationListRequest({}));
        setIsModalOpen(false);
      }
      setIsLoading(false);
    });
  };
  return (
    <>
      <Modal
        className="modal-styles"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        // width={600}
        maskClosable={false}
        centered={true}
        title={false}
      >
        <div className="row">
          <div className="col-md-12">
            <h5 className="table-heading mb-0 ">Create Location</h5>
          </div>
        </div>
        <div className="modal-content">
          <Form
            layout="vertical"
            className="pt-3"
            onFinish={onSave}
            form={form}
          >
            <div className="row">
              <div className="col-sm-12 d-flex justify-content-between align-items-start">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                  <Selects
                    name="state_id"
                    labelText="State"
                    placeText="State"
                    notFoundContent={
                      StateStatus === STATUSES.LOADING ? (
                        <Loading height={"30vh"} />
                      ) : null
                    }
                    onSelect={(_, data) => {
                      onDataChange({
                        state: { id: data.value, name: data.children },
                      });
                    }}
                    onClear={() => {
                      onDataChange({ state: null });
                    }}
                    onClick={getStateData}
                    options={StateData?.collection_list ?? []}
                  />
                  <Selects
                    name="hospital_type_id"
                    labelText="Hospital Type"
                    placeText="Hospital Type"
                    disabled={selectedValues?.state != null ? false : true}
                    value={
                      selectedValues?.hospitalType == null
                        ? undefined
                        : selectedValues.hospitalType
                    }
                    notFoundContent={
                      HospitalTypeStatus === STATUSES.LOADING ? (
                        <Loading height={"30vh"} />
                      ) : null
                    }
                    onSelect={(_, data) => {
                      onDataChange({
                        hospitalType: { id: data.value, name: data.children },
                      });
                    }}
                    onClear={() => {
                      onDataChange({ hospitalType: null });
                    }}
                    onClick={getHospitalTypeData}
                    options={HospitalTypeData ?? []}
                  />
                  <BaseInput
                    inputIcon={<i className="bi bi-pencil icon_style"></i>}
                    labelText="Location"
                    name="name"
                    req
                    placeText="Add Location"
                  />
                  <BaseInput
                    inputIcon={<i className="bi bi-pencil icon_style"></i>}
                    labelText="Postal Code"
                    name="postal_code"
                    placeText="Add Postal Code"
                    req
                    isInteger
                  />
                  <BannerImageCard
                    title="Banner Section Image"
                    imageUrl={bannerSectionImageUrl}
                    isBlogImage={true}
                    setImageFile={setBannerSectionImageFile}
                    setImageUrl={setBannerSectionImageUrl}
                    imageText="Change Image"
                  />
                  <BannerImageCard
                    title="Thumbnail Image"
                    imageUrl={thumbnailImageUrl}
                    isBlogImage={true}
                    setImageFile={setThumbnailImageFile}
                    setImageUrl={setThumbnailImageUrl}
                    imageText="Change Image"
                  />
                </div>
                <SaveButton
                  loading={isLoading ? STATUSES.LOADING : false}
                  title="Save"
                />
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default LocationCreateModal;
