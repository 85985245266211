import React from "react";
import { Input, Form } from "antd";
import styled from "styled-components";
const TextAreaWrapped = styled.div`
  label {
    font-weight: 400;
    font-size: 16px;
    color: #181818;
  }
  .w-100 {
    margin: 0px;
    padding-bottom: 30px;
  }
  .style_input {
    color: rgba(100, 100, 100, 1);
    .ant-input {
      height: 40px;
      color: rgba(100, 100, 100, 1);
      font-weight: 400;
      font-size: 14px;
      @media screen and (max-width: 768px) {
        height: 40px;
      }
    }
  }
`;

const { TextArea } = Input;

const TextAreas = ({
  labelText,
  name,
  placeText,
  areaRow,
  req,
  maxLength,
  marginBottom,
}) => {
  return (
    <TextAreaWrapped>
      <Form.Item
        name={name ?? "name"}
        label={labelText ?? ""}
        className={marginBottom ? `${marginBottom} w-100` : "w-100 labelText"}
        rules={[
          {
            required: req === false ? false : true,
            message: "This field is required!!",
          },
        ]}
      >
        <TextArea
          showCount
          rows={areaRow ? areaRow : "6"}
          placeholder={placeText ?? "Type Here"}
          maxLength={maxLength ?? 2000}
        />
      </Form.Item>
    </TextAreaWrapped>
  );
};

export default TextAreas;
