import React, { useState } from "react";
import Upload from "rc-upload";
import { CloudUploadOutlined } from "@ant-design/icons"; // Assuming you're using Ant Design icons
import { ImageUploadDefaults } from "../../config/constants/defaultValues";

const BannerImageCard = ({
  title,
  imageUrl,
  setImageUrl,
  setImageFile,
  isBlogImage,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  // const MAX_SIZE = 2097152; // 2MB
  // const ALLOWED_EXTENSIONS = ".png, .jpg, .jpeg, .svg, .webp";

  const validateImage = (file) => {
    // Check file size
    if (file.size > ImageUploadDefaults.MAX_SIZE) {
      setErrorMessage("Image size should be ≤ 2MB.");
      return false;
    }
    return true
  };

  const handleBeforeUpload = async (file) => {
    try {
      await validateImage(file);
      setImageFile(file);
      setImageUrl(URL.createObjectURL(file));
      setErrorMessage(""); // Clear any previous error messages
    } catch (error) {
      // Handle validation error
      console.error(error);
    }
  };
  return (
    <>
      <div className="row my-4">
        <div className="banner_text_style mb-1">
          <h4>{title}
          </h4>
        </div>
        <div className="banner_section  ">
          <div className={`banner_section_img_container ${isBlogImage ? "blog_image_style" : "banner_image_style"}`}>
            <img src={imageUrl} alt="Not Found!!" className="banner_image" />
            <div className="upload_btn_container   ">
              <Upload
                accept={ImageUploadDefaults.ALLOWED_EXTENSIONS}
                beforeUpload={handleBeforeUpload}
              >
                <div className="upload_image_style text-capitalize " >
                  <CloudUploadOutlined size={28} />
                  <span>
                    {imageUrl ? "Change" : "Upload"} image
                  </span>
                </div>
              </Upload>
            </div>
          </div>
          {errorMessage && (
            <div className="error_message">{errorMessage}</div>
          )}

        </div>
      </div>
    </>
  );
};
export default BannerImageCard;
