import { Form } from "antd";
import React, { useEffect, useState, } from "react";
import SaveButton from "../../components/button/saveButton";
import BaseInput from "../../components/form/BaseInput";
import { HomeWrapped } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocumTenensPageContentRequest,
  postLocumTenensPageContentRequest,
} from "../../redux/contentManagementSlice";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import Loading from "../../components/shared/GeneralComponents";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";
import VideoCard from "../../components/shared/VideoCard";

const LocumTenensPage = () => {

  const dispatch = useDispatch();
  const { locumTenensPageData, locumTenensPageStatus, locumTenensPageError } =
    useSelector((state) => state.contentManagement);
  const [form] = Form.useForm();
  // Banner Section
  const [bannerSectionImageUrl, setBannerSectionImageUrl] = useState();
  const [bannerSectionImageFile, setBannerSectionImageFile] = useState();
  // Benefits Section Cards
  const [benefitsSectionFirstCardIconUrl, setBenefitsSectionFirstCardIconUrl] = useState();
  const [benefitsSectionFirstCardIconFile, setBenefitsSectionFirstCardIconFile] = useState();
  const [benefitsSectionSecondCardIconUrl, setBenefitsSectionSecondCardIconUrl] = useState();
  const [benefitsSectionSecondCardIconFile, setBenefitsSectionSecondCardIconFile] = useState();
  const [benefitsSectionThirdCardIconUrl, setBenefitsSectionThirdCardIconUrl] = useState();
  const [benefitsSectionThirdCardIconFile, setBenefitsSectionThirdCardIconFile] = useState();
  const [benefitsSectionForthCardIconUrl, setBenefitsSectionForthCardIconUrl] = useState();
  const [benefitsSectionForthCardIconFile, setBenefitsSectionForthCardIconFile] = useState();
  const [benefitsSectionFirstCardHoverIconUrl, setBenefitsSectionFirstCardHoverIconUrl] = useState();
  const [benefitsSectionFirstCardHoverIconFile, setBenefitsSectionFirstCardHoverIconFile] = useState();
  const [benefitsSectionSecondCardHoverIconUrl, setBenefitsSectionSecondCardHoverIconUrl] = useState();
  const [benefitsSectionSecondCardHoverIconFile, setBenefitsSectionSecondCardHoverIconFile] = useState();
  const [benefitsSectionThirdCardHoverIconUrl, setBenefitsSectionThirdCardHoverIconUrl] = useState();
  const [benefitsSectionThirdCardHoverIconFile, setBenefitsSectionThirdCardHoverIconFile] = useState();
  const [benefitsSectionForthCardHoverIconUrl, setBenefitsSectionForthCardHoverIconUrl] = useState();
  const [benefitsSectionForthCardHoverIconFile, setBenefitsSectionForthCardHoverIconFile] = useState();
  // Who Work Locum Tenens Section Video
  const [ourPeopleSectionVideoUrl, setOurPeopleSectionVideoUrl] = useState();
  const [ourPeopleSectionVideoFile, setOurPeopleSectionVideoFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getLocumTenensPageContentRequest(CONTENT_PAGES.LOCUM_TENENS_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (locumTenensPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]":locumTenensPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][firstSubHeading]":locumTenensPageData?.contentPage?.detail?.bannerSection?.firstSubHeading,
        "detail[bannerSection][firstSubDescription]":locumTenensPageData?.contentPage?.detail?.bannerSection?.firstSubDescription,
        "detail[bannerSection][secondSubHeading]":locumTenensPageData?.contentPage?.detail?.bannerSection?.secondSubHeading,
        "detail[bannerSection][secondSubDescription]":locumTenensPageData?.contentPage?.detail?.bannerSection?.secondSubDescription,
        "detail[benefitsSection][firstCardHeading]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.firstCardHeading,
        "detail[benefitsSection][secondCardHeading]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.secondCardHeading,
        "detail[benefitsSection][thirdCardHeading]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.thirdCardHeading,
        "detail[benefitsSection][forthCardHeading]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.forthCardHeading,
        "detail[benefitsSection][heading]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.heading,
        "detail[benefitsSection][description]": locumTenensPageData?.contentPage?.detail?.benefitsSection?.description,
        "detail[videoSection][heading]": locumTenensPageData?.contentPage?.detail?.videoSection?.heading,
        "detail[videoSection][description]": locumTenensPageData?.contentPage?.detail?.videoSection?.description,
        "detail[testimonialsSection][text]": locumTenensPageData?.contentPage?.detail?.testimonialsSection?.text,
        "detail[testimonialsSection][heading]": locumTenensPageData?.contentPage?.detail?.testimonialsSection?.heading,

        //Seo Values Set
        meta_title: locumTenensPageData?.contentPage?.seo_keywords?.meta_title ?? "",
        meta_description: locumTenensPageData?.contentPage?.seo_keywords?.meta_description ?? "",
        is_canonical: locumTenensPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
        robots_meta: locumTenensPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
        meta_author: locumTenensPageData?.contentPage?.seo_keywords?.meta_author ?? "",
        og_type: locumTenensPageData?.contentPage?.seo_keywords?.og_type ?? "",
        og_title: locumTenensPageData?.contentPage?.seo_keywords?.og_title ?? "",
        og_description: locumTenensPageData?.contentPage?.seo_keywords?.og_description ?? "",
        og_image_width: locumTenensPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
        og_image_height: locumTenensPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
        og_url: locumTenensPageData?.contentPage?.seo_keywords?.og_url ?? "",
        og_site_name: locumTenensPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
        og_locale: locumTenensPageData?.contentPage?.seo_keywords?.og_locale ?? "",
        og_locale_alternate: locumTenensPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
        twitter_card: locumTenensPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
        twitter_site: locumTenensPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
        twitter_title: locumTenensPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
        twitter_description: locumTenensPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
        twitter_url: locumTenensPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
      });
      setBannerSectionImageUrl(locumTenensPageData?.contentPage?.contentImages?.bannerSectionImage ?? null);
      setOurPeopleSectionVideoUrl(locumTenensPageData?.contentPage?.contentImages?.ourPeopleSectionVideo ?? null);
      setBenefitsSectionFirstCardIconUrl(locumTenensPageData?.contentPage?.contentImages?.benefitsSectionFirstCardIcon ?? BannerImage);
      setBenefitsSectionSecondCardIconUrl(locumTenensPageData?.contentPage?.contentImages?.benefitsSectionSecondCardIcon ?? BannerImage);
      setBenefitsSectionThirdCardIconUrl(locumTenensPageData?.contentPage?.contentImages?.benefitsSectionThirdCardIcon ?? BannerImage);
      setBenefitsSectionForthCardIconUrl(locumTenensPageData?.contentPage?.contentImages?.benefitsSectionForthCardIcon ?? BannerImage);
      setBenefitsSectionFirstCardHoverIconUrl(locumTenensPageData?.contentPage?.contentImages?.benefitsSectionFirstCardHoverIcon ?? BannerImage);
      setBenefitsSectionSecondCardHoverIconUrl(locumTenensPageData?.contentPage?.contentImages?.benefitsSectionSecondCardHoverIcon ?? BannerImage);
      setBenefitsSectionThirdCardHoverIconUrl(locumTenensPageData?.contentPage?.contentImages?.benefitsSectionThirdCardHoverIcon ?? BannerImage);
      setBenefitsSectionForthCardHoverIconUrl(locumTenensPageData?.contentPage?.contentImages?.benefitsSectionForthCardHoverIcon ?? BannerImage);
      setTwitterImageUrl(locumTenensPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
      setOGImageUrl(locumTenensPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
    }
  }, [locumTenensPageData]);

  const onUpdate = (values) => {

    const data = {
      ...values,
      name: 'Locum Tenens',
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      "contentImages[bannerSectionImage]": bannerSectionImageFile ?? "",
      "contentImages[ourPeopleSectionVideo]": ourPeopleSectionVideoFile ?? "",
      "contentImages[benefitsSectionFirstCardIcon]": benefitsSectionFirstCardIconFile ?? "",
      "contentImages[benefitsSectionSecondCardIcon]": benefitsSectionSecondCardIconFile ?? "",
      "contentImages[benefitsSectionThirdCardIcon]": benefitsSectionThirdCardIconFile ?? "",
      "contentImages[benefitsSectionForthCardIcon]": benefitsSectionForthCardIconFile ?? "",
      "contentImages[benefitsSectionFirstCardHoverIcon]": benefitsSectionFirstCardHoverIconFile ?? "",
      "contentImages[benefitsSectionSecondCardHoverIcon]": benefitsSectionSecondCardHoverIconFile ?? "",
      "contentImages[benefitsSectionThirdCardHoverIcon]": benefitsSectionThirdCardHoverIconFile ?? "",
      "contentImages[benefitsSectionForthCardHoverIcon]": benefitsSectionForthCardHoverIconFile ?? "",
      _method: 'PUT'
    }
    dispatch(postLocumTenensPageContentRequest({ data, id: locumTenensPageData?.contentPage?.id }))
  };

  return (
    <>
      {locumTenensPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : locumTenensPageStatus === STATUSES.ERROR ? (
        <div>{locumTenensPageError}</div>
      ) : (
        <HomeWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}

              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold">Locum Tenens</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section First Sub Heading"
                      name="detail[bannerSection][firstSubHeading]"
                      placeText="Banner Section First Sub Heading"
                    />
                    <TextAreas
                      labelText="Banner Section First Sub Description"
                      name="detail[bannerSection][firstSubDescription]"
                      placeText="Banner Section First Sub Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Second Sub Heading"
                      name="detail[bannerSection][secondSubHeading]"
                      placeText="Banner Section Second Sub Heading"
                    />
                    <TextAreas
                      labelText="Banner Section Second Sub Description"
                      name="detail[bannerSection][secondSubDescription]"
                      placeText="Banner Section Second Sub Description"
                    />
                    <BannerImageCard
                      title="Banner Section Image"
                      imageUrl={bannerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setBannerSectionImageFile}
                      setImageUrl={setBannerSectionImageUrl}
                      imageText="Change Image"
                    />
                    {/* Benefits Section */}
                    <BannerImageCard
                      title="Benefits Section First Card Icon"
                      imageUrl={benefitsSectionFirstCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionFirstCardIconFile}
                      setImageUrl={setBenefitsSectionFirstCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BannerImageCard
                      title="Benefits Section First Card Hover Icon"
                      imageUrl={benefitsSectionFirstCardHoverIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionFirstCardHoverIconFile}
                      setImageUrl={setBenefitsSectionFirstCardHoverIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section First Card Heading"
                      name="detail[benefitsSection][firstCardHeading]"
                      placeText="Benefits Section First Card Heading"
                    />
                    <BannerImageCard
                      title="Benefits Section Second Card Icon"
                      imageUrl={benefitsSectionSecondCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionSecondCardIconFile}
                      setImageUrl={setBenefitsSectionSecondCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BannerImageCard
                      title="Benefits Section Second Card Hover Icon"
                      imageUrl={benefitsSectionSecondCardHoverIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionSecondCardHoverIconFile}
                      setImageUrl={setBenefitsSectionSecondCardHoverIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section Second Card Heading"
                      name="detail[benefitsSection][secondCardHeading]"
                      placeText="Benefits Section Second Card Heading"
                    />
                    <BannerImageCard
                      title="Benefits Section Third Card Icon"
                      imageUrl={benefitsSectionThirdCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionThirdCardIconFile}
                      setImageUrl={setBenefitsSectionThirdCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BannerImageCard
                      title="Benefits Section Third Card Hover Icon"
                      imageUrl={benefitsSectionThirdCardHoverIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionThirdCardHoverIconFile}
                      setImageUrl={setBenefitsSectionThirdCardHoverIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section Third Card Heading"
                      name="detail[benefitsSection][thirdCardHeading]"
                      placeText="Benefits Section Third Card Heading"
                    />
                    <BannerImageCard
                      title="Benefits Section Forth Card Icon"
                      imageUrl={benefitsSectionForthCardIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionForthCardIconFile}
                      setImageUrl={setBenefitsSectionForthCardIconUrl}
                      imageText="Change Icon"
                    />
                    <BannerImageCard
                      title="Benefits Section Forth Card Hover Icon"
                      imageUrl={benefitsSectionForthCardHoverIconUrl}
                      isBlogImage={true}
                      setImageFile={setBenefitsSectionForthCardHoverIconFile}
                      setImageUrl={setBenefitsSectionForthCardHoverIconUrl}
                      imageText="Change Icon"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section Forth Card Heading"
                      name="detail[benefitsSection][forthCardHeading]"
                      placeText="Benefits Section Forth Card Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Benefits Section Heading"
                      name="detail[benefitsSection][heading]"
                      placeText="Benefits Section Heading"
                    />
                    <TextAreas
                      labelText="Benefits Section Description"
                      name="detail[benefitsSection][description]"
                      placeText="Benefits Section Description"
                    />
                    {/* Video Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Video Section Heading"
                      name="detail[videoSection][heading]"
                      placeText="Video Section Heading"
                    />
                    <TextAreas
                      labelText="Video Section Description"
                      name="detail[videoSection][description]"
                      placeText="Video Section Description"
                    />
                    <label>Our People Section Video *</label>
                    <VideoCard
                      width={400} height={300}
                      videoUrl={ourPeopleSectionVideoUrl}
                      setVideoFile={setOurPeopleSectionVideoFile}
                      setVideoUrl={setOurPeopleSectionVideoUrl}
                    />
                    <br/>
                    {/* Testimonials Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Text"
                      name="detail[testimonialsSection][text]"
                      placeText="Testimonials Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Heading"
                      name="detail[testimonialsSection][heading]"
                      placeText="Testimonials Section Heading"
                    />                    
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>
              </Form>
            </div>
          </div>
        </HomeWrapped>
      )}
    </>
  );
};

export default LocumTenensPage;
