import { Modal } from "antd";
import React from "react";
import profile from "../../assets/images/profile.png";
import upload from "../../assets/images/upload.png";


const ReferralDetailModal = ({
    isModalOpen,
    setIsModalOpen,
    modalData,
}) => {

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    console.log('modelData', modalData)
    return (
        <>
            <Modal
                className="modal-styles"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                // width={600}
                maskClosable={false}
                centered={true}
                title={false}
            >
                <div className="row">
                    <div className="col-md-12">
                        <h5 className="table-heading mb-0 ">Referral Detail</h5>
                    </div>
                </div>
                <div className="modal-content">
                    <div className="row">
                        <div className="col-lg-5">
                            {/* <h6 className="modal-heading" >Referral</h6> */}
                            <div className="d-flex align-items-center user-profile">
                                <div>
                                    <img src={profile} alt="" className="img-fluid" />
                                </div>
                                <div className="ms-3">
                                    <h3 className="mb-0"> {modalData?.name ?? ""}</h3>
                                    <p className="mb-0">{modalData?.discipline?.name ?? ""}</p>
                                </div>
                            </div>
                        </div>
                        {modalData?.job != null ? <div className="col-lg-7 mx-auto mt-2">
                            <div className="Applied-box mt-3 mt-lg-0 d-sm-flex align-items-center justify-content-between">
                                <div className="">
                                    <h6 className="modal-heading">{modalData?.job?.discipline?.name ?? ""}</h6>
                                    <div className="d-sm-flex align-items-center">
                                        <span>Job Location:</span>
                                        <p className="mb-0 ms-2">{modalData?.job?.location?.name ?? ""}</p>
                                    </div>
                                </div>
                            </div>
                        </div> :
                            <>
                            </>}
                        <div className="mt-3">
                            <div className="d-sm-flex ">
                                <div className="col-sm-6">
                                    <div className="user-data">
                                        <h6>Email Address</h6>
                                        <p>{modalData?.email ?? ""}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="user-data">
                                        <h6>Phone Number</h6>
                                        <p>{modalData?.phone ?? ""}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-sm-flex ">
                                <div className="col-sm-6">
                                    <div className="user-data">
                                        <h6>Discipline</h6>
                                        <p>{modalData?.discipline?.name ?? ""}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="user-data">
                                        <h6>Referral Date</h6>
                                        <p>{modalData?.updated_at ?? ""}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 mt-3">
                            <h6 className="modal-heading">Referral by:</h6>

                            <div className="d-flex align-items-center user-profile">
                                <div>
                                    <img src={profile} alt="" className="img-fluid" />
                                </div>
                                <div className="ms-3">
                                    <h3 className="mb-0"> {modalData?.referred_by?.first_name ?? ""}&nbsp;
                                        {modalData?.referred_by?.last_name ?? ""}</h3>
                                    <p className="mb-0">{modalData?.referred_by?.discipline?.name ?? ""}</p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-3">
                            <div className="d-sm-flex ">
                                <div className="col-sm-6">
                                    <div className="user-data">
                                        <h6>Email Address</h6>
                                        <p>{modalData?.referred_by?.email ?? ""}</p>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="user-data">
                                        <h6>Phone Number</h6>
                                        <p>{modalData?.referred_by?.phone ?? ""}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default ReferralDetailModal;
