import Axios from "axios";
import { SERVER_URL } from "../constants/defaultValues";

const callAPI = async (request) => {
  const { url: URL, method, data, withoutToken, customUrl } = request;
  const url = customUrl ?? (SERVER_URL + URL);
  const headers = withoutToken
    ? {
      Authorization: "Bearer " + JSON.parse(localStorage.getItem('user')).token,
      "Content-Type": "application/json",
    }
    : {
      "Content-Type": "application/json",
    };
  const res = await Axios({
    method,
    headers: headers,
    url,
    data,
  })
    .then((res) => {
      console.log("Response from axios.serviceNow Now", res);
      return res;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
      const errorResponse = { status: 500, message: "Network Error" };
      return errorResponse;
    });
  return res;
};
export default callAPI;
