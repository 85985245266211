import { Form } from "antd";
import React, { useEffect, useState } from "react";
import BaseInput from "../../components/form/BaseInput";
import { ContentWrapped } from "./style";
import SaveButton from "../../components/button/saveButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getAboutPageContentRequest,
  postAboutPageContentRequest
} from "../../redux/contentManagementSlice";
import Loading from "../../components/shared/GeneralComponents";
import { BannerImage, CONTENT_PAGES, STATUSES } from "../../config/constants/defaultValues";
import TextAreas from "../../components/form/TextAreas";
import SeoSection from "../../components/shared/seoSection";
import BannerImageCard from "../../components/shared/bannerImageCard";
import VideoCard from "../../components/shared/VideoCard";

const AboutPage = () => {
  const dispatch = useDispatch();
  const { aboutPageData, aboutPageStatus, aboutPageError } = useSelector(
    (state) => state.contentManagement
  );
  const [form] = Form.useForm();

  // Banner Video Home Page
  const [bannerSectionVideoUrl, setBannerSectionVideoUrl] = useState();
  const [bannerSectionVideoFile, setBannerSectionVideoFile] = useState();
  
  // About Section Images
  const [worksSectionFirstCardImageUrl, setWorksSectionFirstCardImageUrl] = useState();
  const [worksSectionFirstCardImageFile, setWorksSectionFirstCardImageFile] = useState();
  const [worksSectionSecondCardImageUrl, setWorksSectionSecondCardImageUrl] = useState();
  const [worksSectionSecondCardImageFile, setWorksSectionSecondCardImageFile] = useState();
  const [worksSectionThirdCardImageUrl, setWorksSectionThirdCardImageUrl] = useState();
  const [worksSectionThirdCardImageFile, setWorksSectionThirdCardImageFile] = useState();
  // Power Section Images
  const [powerSectionImageUrl, setPowerSectionImageUrl] = useState();
  const [powerSectionImageFile, setPowerSectionImageFile] = useState();
  // Commitment Section Images
  const [commitmentSectionImageUrl, setCommitmentSectionImageUrl] = useState();
  const [commitmentSectionImageFile, setCommitmentSectionImageFile] = useState();
  //Seo Section states
  const [ogImageUrl, setOGImageUrl] = useState();
  const [ogImageFile, setOGImageFile] = useState();
  const [twitterImageUrl, setTwitterImageUrl] = useState();
  const [twitterImageFile, setTwitterImageFile] = useState();

  useEffect(() => {
    dispatch(getAboutPageContentRequest(CONTENT_PAGES.ABOUT_US_PAGE));
  }, [dispatch]);

  useEffect(() => {
    if (aboutPageData) {
      form.setFieldsValue({
        "detail[bannerSection][heading]": aboutPageData?.contentPage?.detail?.bannerSection?.heading,
        "detail[bannerSection][description]":aboutPageData?.contentPage?.detail?.bannerSection?.description,
        "detail[statsSection][text]": aboutPageData?.contentPage?.detail?.statsSection?.text,
        "detail[statsSection][heading]": aboutPageData?.contentPage?.detail?.statsSection?.heading,
        "detail[statsSection][firstCounterValue]": aboutPageData?.contentPage?.detail?.statsSection?.firstCounterValue,
        "detail[statsSection][firstCounterText]": aboutPageData?.contentPage?.detail?.statsSection?.firstCounterText,
        "detail[statsSection][secondCounterValue]": aboutPageData?.contentPage?.detail?.statsSection?.secondCounterValue,
        "detail[statsSection][secondCounterText]": aboutPageData?.contentPage?.detail?.statsSection?.secondCounterText,
        "detail[statsSection][thirdCounterValue]": aboutPageData?.contentPage?.detail?.statsSection?.thirdCounterValue,
        "detail[statsSection][thirdCounterText]": aboutPageData?.contentPage?.detail?.statsSection?.thirdCounterText,
        "detail[statsSection][forthCounterValue]": aboutPageData?.contentPage?.detail?.statsSection?.forthCounterValue,
        "detail[statsSection][forthCounterText]": aboutPageData?.contentPage?.detail?.statsSection?.forthCounterText,
        "detail[worksSection][heading]": aboutPageData?.contentPage?.detail?.worksSection?.heading,
        "detail[worksSection][description]": aboutPageData?.contentPage?.detail?.worksSection?.description,
        "detail[worksSection][firstCardHeading]": aboutPageData?.contentPage?.detail?.worksSection?.firstCardHeading,
        "detail[worksSection][firstCardDescription]": aboutPageData?.contentPage?.detail?.worksSection?.firstCardDescription,
        "detail[worksSection][secondCardHeading]": aboutPageData?.contentPage?.detail?.worksSection?.secondCardHeading,
        "detail[worksSection][secondCardDescription]": aboutPageData?.contentPage?.detail?.worksSection?.secondCardDescription,
        "detail[worksSection][thirdCardHeading]": aboutPageData?.contentPage?.detail?.worksSection?.thirdCardHeading,
        "detail[worksSection][thirdCardDescription]": aboutPageData?.contentPage?.detail?.worksSection?.thirdCardDescription,
        "detail[powerSection][text]": aboutPageData?.contentPage?.detail?.powerSection?.text,
        "detail[powerSection][heading]": aboutPageData?.contentPage?.detail?.powerSection?.heading,
        "detail[powerSection][description]": aboutPageData?.contentPage?.detail?.powerSection?.description,
        "detail[powerSection][firstBulletPoint]": aboutPageData?.contentPage?.detail?.powerSection?.firstBulletPoint,
        "detail[powerSection][secondBulletPoint]": aboutPageData?.contentPage?.detail?.powerSection?.secondBulletPoint,
        "detail[powerSection][thirdBulletPoint]": aboutPageData?.contentPage?.detail?.powerSection?.thirdBulletPoint,
        "detail[powerSection][forthBulletPoint]": aboutPageData?.contentPage?.detail?.powerSection?.forthBulletPoint,
        "detail[powerSection][fifthBulletPoint]": aboutPageData?.contentPage?.detail?.powerSection?.fifthBulletPoint,
        "detail[powerSection][buttonText]": aboutPageData?.contentPage?.detail?.powerSection?.buttonText,
        "detail[commitmentSection][text]": aboutPageData?.contentPage?.detail?.commitmentSection?.text,
        "detail[commitmentSection][heading]": aboutPageData?.contentPage?.detail?.commitmentSection?.heading,
        "detail[commitmentSection][description]": aboutPageData?.contentPage?.detail?.commitmentSection?.description,
        "detail[commitmentSection][buttonText]": aboutPageData?.contentPage?.detail?.commitmentSection?.buttonText,
        "detail[testimonialsSection][text]": aboutPageData?.contentPage?.detail?.testimonialsSection?.text,
        "detail[testimonialsSection][heading]": aboutPageData?.contentPage?.detail?.testimonialsSection?.heading,

        //Seo Values Set
      meta_title: aboutPageData?.contentPage?.seo_keywords?.meta_title ?? "",
      meta_description: aboutPageData?.contentPage?.seo_keywords?.meta_description ?? "",
      is_canonical: aboutPageData?.contentPage?.seo_keywords?.is_canonical === 1 ? true : false,
      robots_meta: aboutPageData?.contentPage?.seo_keywords?.robots_meta ?? "",
      meta_author: aboutPageData?.contentPage?.seo_keywords?.meta_author ?? "",
      og_type: aboutPageData?.contentPage?.seo_keywords?.og_type ?? "",
      og_title: aboutPageData?.contentPage?.seo_keywords?.og_title ?? "",
      og_description: aboutPageData?.contentPage?.seo_keywords?.og_description ?? "",
      og_image_width: aboutPageData?.contentPage?.seo_keywords?.og_image_width ?? "",
      og_image_height: aboutPageData?.contentPage?.seo_keywords?.og_image_height ?? "",
      og_url: aboutPageData?.contentPage?.seo_keywords?.og_url ?? "",
      og_site_name: aboutPageData?.contentPage?.seo_keywords?.og_site_name ?? "",
      og_locale: aboutPageData?.contentPage?.seo_keywords?.og_locale ?? "",
      og_locale_alternate: aboutPageData?.contentPage?.seo_keywords?.og_locale_alternate ?? "",
      twitter_card: aboutPageData?.contentPage?.seo_keywords?.twitter_card ?? "",
      twitter_site: aboutPageData?.contentPage?.seo_keywords?.twitter_site ?? "",
      twitter_title: aboutPageData?.contentPage?.seo_keywords?.twitter_title ?? "",
      twitter_description: aboutPageData?.contentPage?.seo_keywords?.twitter_description ?? "",
      twitter_url: aboutPageData?.contentPage?.seo_keywords?.twitter_url ?? "",
    });
    setBannerSectionVideoUrl(aboutPageData?.contentPage?.contentImages?.bannerVideo ?? null);
    setWorksSectionFirstCardImageUrl(aboutPageData?.contentPage?.contentImages?.worksSectionFirstCardImage ?? BannerImage);
    setWorksSectionSecondCardImageUrl(aboutPageData?.contentPage?.contentImages?.worksSectionSecondCardImage ?? BannerImage);
    setWorksSectionThirdCardImageUrl(aboutPageData?.contentPage?.contentImages?.worksSectionThirdCardImage ?? BannerImage);
    setPowerSectionImageUrl(aboutPageData?.contentPage?.contentImages?.powerSectionImage ?? BannerImage);
    setCommitmentSectionImageUrl(aboutPageData?.contentPage?.contentImages?.commitmentSectionImage ?? BannerImage);
    setTwitterImageUrl(aboutPageData?.contentPage?.seo_keywords?.twitter_image ?? BannerImage);
    setOGImageUrl(aboutPageData?.contentPage?.seo_keywords?.og_image ?? BannerImage);
    }
  }, [aboutPageData]);

  const onUpdate = (values) => {
    const data={
      ...values,
      name:'About Us',
      "contentImages[bannerVideo]": bannerSectionVideoFile ?? "",
      "contentImages[worksSectionFirstCardImage]": worksSectionFirstCardImageFile ?? "",
      "contentImages[worksSectionSecondCardImage]": worksSectionSecondCardImageFile ?? "",
      "contentImages[worksSectionThirdCardImage]": worksSectionThirdCardImageFile ?? "",
      "contentImages[powerSectionImage]": powerSectionImageFile ?? "",
      "contentImages[commitmentSectionImage]": commitmentSectionImageFile ?? "",
      "og_image": ogImageFile ?? "",
      "twitter_image": twitterImageFile ?? "",
      "is_canonical": values.is_canonical === true ? 1 : 0,
      _method: 'PUT'
    }
    dispatch(postAboutPageContentRequest({data,id:aboutPageData?.contentPage?.id})) 
  };
  return (
    <>
      {aboutPageStatus === STATUSES.LOADING ? (
        <Loading />
      ) : aboutPageError === STATUSES.ERROR ? (
        <div>{aboutPageError}</div>
      ) : (
        <ContentWrapped>
          <div className="row">
            <div className="col-sm-12">
              <Form
                layout="vertical"
                className="pt-3"
                form={form}
                onFinish={onUpdate}
              >
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-between align-items-start mb-4">
                    <h3 className="font-bold" >About Page</h3>
                    <SaveButton title="Update" />
                  </div>
                </div>
                <div className="row">
                <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                    {/* Banner Section */}
                    {/* <HeadingTitle title="Banner Section" /> */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Banner Section Heading"
                      name="detail[bannerSection][heading]"
                      placeText="Banner Section Heading"
                    />
                    <TextAreas
                     labelText="Banner Section Description"
                     name="detail[bannerSection][description]"
                     placeText="Banner Section Description"
                    />
                    <label>Banner Section Video *</label>
                    <VideoCard
                      width={400} height={300}
                      videoUrl={bannerSectionVideoUrl}
                      setVideoFile={setBannerSectionVideoFile}
                      setVideoUrl={setBannerSectionVideoUrl}
                    />
                    <br/>
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Text"
                      name="detail[statsSection][text]"
                      placeText="Stats Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Heading"
                      name="detail[statsSection][heading]"
                      placeText="Stats Section Heading"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section First Counter Value"
                      name="detail[statsSection][firstCounterValue]"
                      placeText="Stats Section First Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section First Counter Text"
                      name="detail[statsSection][firstCounterText]"
                      placeText="Stats Section First Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Second Counter Value"
                      name="detail[statsSection][secondCounterValue]"
                      placeText="Stats Section Second Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Second Counter Text"
                      name="detail[statsSection][secondCounterText]"
                      placeText="Stats Section Second Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Third Counter Value"
                      name="detail[statsSection][thirdCounterValue]"
                      placeText="Stats Section Third Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Third Counter Text"
                      name="detail[statsSection][thirdCounterText]"
                      placeText="Stats Section Third Counter Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Forth Counter Value"
                      name="detail[statsSection][forthCounterValue]"
                      placeText="Stats Section Forth Counter Value"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Stats Section Forth Counter Text"
                      name="detail[statsSection][forthCounterText]"
                      placeText="Stats Section Forth Counter Text"
                    />
                    {/* How it works Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Works Section Heading"
                      name="detail[worksSection][heading]"
                      placeText="Works Section Heading"
                    />
                    <TextAreas
                     labelText="Works Section Description"
                     name="detail[worksSection][description]"
                     placeText="Works Section Description"
                    />
                    <BannerImageCard
                      title="Works Section First Card Image"
                      imageUrl={worksSectionFirstCardImageUrl}
                      isBlogImage={true}
                      setImageFile={setWorksSectionFirstCardImageFile}
                      setImageUrl={setWorksSectionFirstCardImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Works Section First Card Heading"
                      name="detail[worksSection][firstCardHeading]"
                      placeText="Works Section First Card Heading"
                    />
                    <TextAreas
                     labelText="Works Section First Card Description"
                     name="detail[worksSection][firstCardDescription]"
                     placeText="Works Section First Card Description"
                    />
                    <BannerImageCard
                      title="Works Section Second Card Image"
                      imageUrl={worksSectionSecondCardImageUrl}
                      isBlogImage={true}
                      setImageFile={setWorksSectionSecondCardImageFile}
                      setImageUrl={setWorksSectionSecondCardImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Works Section Second Card Heading"
                      name="detail[worksSection][secondCardHeading]"
                      placeText="Works Section Second Card Heading"
                    />
                    <TextAreas
                     labelText="Works Section Second Card Description"
                     name="detail[worksSection][secondCardDescription]"
                     placeText="Works Section Second Card Description"
                    />
                    <BannerImageCard
                      title="Works Section Third Card Image"
                      imageUrl={worksSectionThirdCardImageUrl}
                      isBlogImage={true}
                      setImageFile={setWorksSectionThirdCardImageFile}
                      setImageUrl={setWorksSectionThirdCardImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Works Section Third Card Heading"
                      name="detail[worksSection][thirdCardHeading]"
                      placeText="Works Section Third Card Heading"
                    />
                    <TextAreas
                     labelText="Works Section Third Card Description"
                     name="detail[worksSection][thirdCardDescription]"
                     placeText="Works Section Third Card Description"
                    />
                    {/* Power Section */}
                    <BannerImageCard
                      title="Power Section Image"
                      imageUrl={powerSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setPowerSectionImageFile}
                      setImageUrl={setPowerSectionImageUrl}
                      imageText="Change Image"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Power Section Text"
                      name="detail[powerSection][text]"
                      placeText="Power Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Power Section Heading"
                      name="detail[powerSection][heading]"
                      placeText="Power Section Heading"
                    />
                    <TextAreas
                     labelText="Power Section Description"
                     name="detail[powerSection][description]"
                     placeText="Power Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Power Section First Bullet Point"
                      name="detail[powerSection][firstBulletPoint]"
                      placeText="Power Section First Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Power Section Second Bullet Point"
                      name="detail[powerSection][secondBulletPoint]"
                      placeText="Power Section Second Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Power Section Third Bullet Point"
                      name="detail[powerSection][thirdBulletPoint]"
                      placeText="Power Section Third Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Power Section Forth Bullet Point"
                      name="detail[powerSection][forthBulletPoint]"
                      placeText="Power Section Forth Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Power Section Fifth Bullet Point"
                      name="detail[powerSection][fifthBulletPoint]"
                      placeText="Power Section Fifth Bullet Point"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Power Section Button Text"
                      name="detail[powerSection][buttonText]"
                      placeText="Power Section Button Text"
                    />
                    {/* Commitment Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Commitment Section Text"
                      name="detail[commitmentSection][text]"
                      placeText="Commitment Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Commitment Section Heading"
                      name="detail[commitmentSection][heading]"
                      placeText="Commitment Section Heading"
                    />
                    <TextAreas
                     labelText="Commitment Section Description"
                     name="detail[commitmentSection][description]"
                     placeText="Commitment Section Description"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Commitment Section Button Text"
                      name="detail[commitmentSection][buttonText]"
                      placeText="Commitment Section Button Text"
                    />
                    <BannerImageCard
                      title="Commitment Section Image"
                      imageUrl={commitmentSectionImageUrl}
                      isBlogImage={true}
                      setImageFile={setCommitmentSectionImageFile}
                      setImageUrl={setCommitmentSectionImageUrl}
                      imageText="Change Image"
                    />
                    
                    {/* Testimonials Section */}
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Text"
                      name="detail[testimonialsSection][text]"
                      placeText="Testimonials Section Text"
                    />
                    <BaseInput
                      inputIcon={<i className="bi bi-pencil icon_style"></i>}
                      labelText="Testimonials Section Heading"
                      name="detail[testimonialsSection][heading]"
                      placeText="Testimonials Section Heading"
                    />
                  </div>
                  <SeoSection ogImageUrl={ogImageUrl}
                    setOGImageFile={setOGImageFile}
                    setOGImageUrl={setOGImageUrl}
                    twitterImageUrl={twitterImageUrl}
                    setTwitterImageFile={setTwitterImageFile}
                    setTwitterImageUrl={setTwitterImageUrl} />
                </div>

              </Form>
            </div>
          </div>
        </ContentWrapped>
      )}
    </>
  );
};

export default AboutPage;
